import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class SliderModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETSLIDER](data) {
    ApiService.setHeader();
    return ApiService.get(
      `slider?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
  @Action({ rawError: true })
  [Actions.SAVESLIDER](data) {
    ApiService.setHeader();
    return ApiService.post(`slider/add`, data);
  }
  @Action({ rawError: true })
  [Actions.GETSLIDERDETAIL](id) {
    ApiService.setHeader();
    return ApiService.get(`slider/${id}`);
  }
  @Action({ rawError: true })
  [Actions.UPDATESLIDER](data) {
    ApiService.setHeader();
    return ApiService.put(`slider/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.DELETESLIDER](id) {
    ApiService.setHeader();
    return ApiService.delete(`slider/${id}`);
  }
}
