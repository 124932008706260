import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class TableModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETTABLE](data) {
    ApiService.setHeader();
    return ApiService.get(`${data.url}?${data.params}`);
  }
}
