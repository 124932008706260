import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ProsesPermohonanModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.PROSESPENGAJUAN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `permohonan_izin?q=${data.search}&page=${data.page}&limit=${data.limit}&order=-permohonanizin.id&module=proses-pengajuan-list&useSlaList=1`
    );
  }

  @Action({ rawError: true })
  [Actions.DOWNLOADREPORT](data) {
    ApiService.setHeaderAcceptBlob();
    return ApiService.get(
      `TemplateData/generateReport/${data.id}?key_id=${data.permohonanIzin}`
    );
  }

  @Action({ rawError: true })
  [Actions.NEXTPROSES](data) {
    ApiService.setHeader();
    return ApiService.post(`PermohonanIzin/getnextprocess`, data);
  }
}
