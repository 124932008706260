export default {
  namespaced: true,
  state: {
    errorlist: {},
  },
  mutations: {
    SET_ERROR_LIST(state, valueArr) {
      state.errorlist = valueArr;
    },
  },
  actions: {
    setErrorList({ commit }, payload) {
      commit("SET_ERROR_LIST", payload);
    },
  },
};
