import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class AssessmentModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETASSESSMENTACTIVE](params) {
    ApiService.setHeader();
    return ApiService.get(`FormAssessment/getActiveEvent${params}`);
  }

  @Action({ rawError: true })
  [Actions.SAVEASSESSMENTACTIVE](data) {
    ApiService.setHeader();
    return ApiService.post(`FormAssessment/saveAssessment`, data);
  }

  //   @Action({ rawError: true })
  //   [Actions.DETAILARTIKELKATEGORI](id) {
  //     ApiService.setHeader();
  //     return ApiService.get(`portalArtikelKategori/view/${id}`);
  //   }

  //   @Action({ rawError: true })
  //   [Actions.UBAHARTIKELKATEGORI](data) {
  //     ApiService.setHeader();
  //     return ApiService.post(`portalArtikelKategori/edit/${data.id}`, data);
  //   }

  //   @Action({ rawError: true })
  //   [Actions.DELETEARTIKELKATEGORI](id) {
  //     ApiService.setHeader();
  //     return ApiService.get(`portalArtikelKategori/delete/${id}`);
  //   }
}
