import store from "@/store";

const menu = [
  {
    label_menu: "Home",
    tautan: "home",
    icon: "zmdi zmdi-home",
    _matchingData: {
      PeranMenu: {},
    },
    children: [],
  },
  {
    id: 12,
    label_menu: "Setting",
    tautan: "#",
    no_urut: 2,
    icon: "zmdi zmdi-settings",
    _matchingData: {
      PeranMenu: {
        id: 409,
        peran_id: 2,
        menu_id: 12,
      },
    },
    children: [
      {
        id: 13,
        label_menu: "Struktur Instansi",
        tautan: "unit",
        parent_id: 12,
        icon: "zmdi zmdi-balance",
        _matchingData: {
          PeranMenu: {
            id: 415,
            peran_id: 2,
            menu_id: 13,
          },
        },
        children: [],
      },
      {
        id: 6,
        label_menu: "Authorisasi",
        tautan: "#",
        parent_id: 12,
        no_urut: 1,
        icon: "zmdi zmdi-accounts-list-alt",
        _matchingData: {
          PeranMenu: {
            id: 414,
            peran_id: 2,
            menu_id: 6,
          },
        },
        children: [
          {
            id: 65,
            label_menu: "Master Jabatan",
            tautan: "form/list/68",
            parent_id: 6,
            no_urut: 3,
            icon: "zmdi zmdi-bookmark",
            _matchingData: {
              PeranMenu: {
                id: 511,
                peran_id: 2,
                menu_id: 65,
              },
            },
            children: [],
          },
          {
            id: 8,
            label_menu: "Peran",
            tautan: "peran",
            parent_id: 6,
            no_urut: 6,
            icon: "zmdi zmdi-accounts",
            _matchingData: {
              PeranMenu: {
                id: 21171,
                peran_id: 2,
                menu_id: 8,
              },
            },
            children: [],
          },
          {
            id: 7,
            label_menu: "Pengguna",
            tautan: "pengguna",
            parent_id: 6,
            no_urut: 7,
            icon: "zmdi zmdi-account",
            _matchingData: {
              PeranMenu: {
                id: 423,
                peran_id: 2,
                menu_id: 7,
              },
            },
            children: [],
          },
          {
            id: 47,
            label_menu: "Pegawai Instansi",
            tautan: "form/list/52",
            parent_id: 6,
            no_urut: 100,
            icon: "zmdi zmdi-assignment-account",
            _matchingData: {
              PeranMenu: {
                id: 429,
                peran_id: 2,
                menu_id: 47,
              },
            },
            children: [],
          },
        ],
      },
      {
        id: 38,
        label_menu: "Seting Umum",
        tautan: "#",
        parent_id: 12,
        no_urut: 4,
        icon: "zmdi zmdi-settings-square",
        _matchingData: {
          PeranMenu: {
            id: 410,
            peran_id: 2,
            menu_id: 38,
          },
        },
        children: [
          {
            id: 36,
            label_menu: "Kalender",
            tautan: "kalender",
            parent_id: 38,
            no_urut: 600,
            _matchingData: {
              PeranMenu: {
                id: 411,
                peran_id: 2,
                menu_id: 36,
              },
            },
            children: [],
          },
        ],
      },
      {
        id: 18,
        label_menu: "Seting Perizinan",
        tautan: "#",
        parent_id: 12,
        no_urut: 8,
        icon: "zmdi zmdi-check-all",
        _matchingData: {
          PeranMenu: {
            id: 404,
            peran_id: 2,
            menu_id: 18,
          },
        },
        children: [
          {
            id: 17,
            label_menu: "Jenis Izin",
            tautan: "jenis-izin",
            parent_id: 18,
            no_urut: 50,
            _matchingData: {
              PeranMenu: {
                id: 402,
                peran_id: 2,
                menu_id: 17,
              },
            },
            children: [],
          },
          {
            id: 15,
            label_menu: "Alur Proses",
            tautan: "alur-proses",
            parent_id: 18,
            no_urut: 51,
            _matchingData: {
              PeranMenu: {
                id: 424,
                peran_id: 2,
                menu_id: 15,
              },
            },
            children: [],
          },
          {
            id: 165,
            label_menu: "Daftar Nomenklatur Unit",
            tautan: "form/list/32033",
            parent_id: 18,
            no_urut: 100,
            icon: "zmdi zmdi-balance",
            _matchingData: {
              PeranMenu: {
                id: 57265,
                peran_id: 2,
                menu_id: 165,
              },
            },
            children: [],
          },
          {
            id: 34,
            label_menu: "Tarif Izin",
            tautan: "tarif-izin",
            parent_id: 18,
            no_urut: 200,
            _matchingData: {
              PeranMenu: {
                id: 407,
                peran_id: 2,
                menu_id: 34,
              },
            },
            children: [],
          },
          {
            id: 33,
            label_menu: "Penomoran",
            tautan: "penomoran",
            parent_id: 18,
            no_urut: 300,
            _matchingData: {
              PeranMenu: {
                id: 406,
                peran_id: 2,
                menu_id: 33,
              },
            },
            children: [],
          },
          {
            id: 68,
            label_menu: "Report Component",
            tautan: "report-component",
            parent_id: 18,
            no_urut: 300,
            icon: "zmdi zmdi-developer-board",
            _matchingData: {
              PeranMenu: {
                id: 546,
                peran_id: 2,
                menu_id: 68,
              },
            },
            children: [],
          },
          {
            id: 53,
            label_menu: "Notifikasi",
            tautan: "notifikasi",
            parent_id: 18,
            no_urut: 500,
            _matchingData: {
              PeranMenu: {
                id: 542,
                peran_id: 2,
                menu_id: 53,
              },
            },
            children: [],
          },
        ],
      },
      {
        id: 26,
        label_menu: "Form & Data",
        tautan: "#",
        parent_id: 12,
        no_urut: 100,
        icon: "zmdi zmdi-code-setting",
        _matchingData: {
          PeranMenu: {
            id: 405,
            peran_id: 2,
            menu_id: 26,
          },
        },
        children: [
          {
            id: 111,
            label_menu: "Unduh Aplikasi",
            tautan: "form/list/11125",
            parent_id: 26,
            no_urut: 16,
            icon: "zmdi zmdi-case-download",
            _matchingData: {
              PeranMenu: {
                id: 25492,
                peran_id: 2,
                menu_id: 111,
              },
            },
            children: [],
          },
          {
            id: 14,
            label_menu: "Kamus Data",
            tautan: "kamus-data",
            parent_id: 26,
            no_urut: 300,
            icon: "zmdi zmdi-collection-text",
            _matchingData: {
              PeranMenu: {
                id: 1276,
                peran_id: 2,
                menu_id: 14,
              },
            },
            children: [],
          },
          {
            id: 20,
            label_menu: "Template Data",
            tautan: "template-data",
            parent_id: 26,
            no_urut: 301,
            icon: "zmdi zmdi-collection-bookmark",
            _matchingData: {
              PeranMenu: {
                id: 419,
                peran_id: 2,
                menu_id: 20,
              },
            },
            children: [],
          },
          {
            id: 25,
            label_menu: "Template Form",
            tautan: "template-form",
            parent_id: 26,
            no_urut: 500,
            icon: "zmdi zmdi-code",
            _matchingData: {
              PeranMenu: {
                id: 418,
                peran_id: 2,
                menu_id: 25,
              },
            },
            children: [],
          },
          {
            id: 75,
            label_menu: "Element Options",
            tautan: "form/list/96",
            parent_id: 26,
            no_urut: 501,
            icon: "zmdi zmdi-assignment",
            _matchingData: {
              PeranMenu: {
                id: 558,
                peran_id: 2,
                menu_id: 75,
              },
            },
            children: [],
          },
          {
            id: 54,
            label_menu: "Web Service Eksternal",
            tautan: "service-eksternal",
            parent_id: 26,
            no_urut: 600,
            icon: "zmdi zmdi-cloud-outline-alt",
            _matchingData: {
              PeranMenu: {
                id: 75661,
                peran_id: 2,
                menu_id: 54,
              },
            },
            children: [],
          },
          {
            id: 101,
            label_menu: "SMS Notifikasi",
            tautan: "form/list/294",
            parent_id: 26,
            no_urut: 1000,
            icon: "zmdi zmdi-assignment",
            _matchingData: {
              PeranMenu: {
                id: 30569,
                peran_id: 2,
                menu_id: 101,
              },
            },
            children: [],
          },
        ],
      },
      {
        id: 319,
        label_menu: "SKM",
        tautan: "#",
        parent_id: 12,
        no_urut: 100,
        icon: "zmdi zmdi-assignment-check",
        _matchingData: {
          PeranMenu: {
            id: 73564,
            peran_id: 2,
            menu_id: 319,
          },
        },
        children: [
          {
            id: 320,
            label_menu: "Setting Survey",
            tautan: "form-assessment",
            parent_id: 319,
            no_urut: 1,
            icon: "zmdi zmdi-assignment-check",
            _matchingData: {
              PeranMenu: {
                id: 73565,
                peran_id: 2,
                menu_id: 320,
              },
            },
            children: [],
          },
          {
            id: 321,
            label_menu: "Hasil Survey",
            tautan: "list-periode",
            parent_id: 319,
            no_urut: 2,
            icon: "zmdi zmdi-format-list-bulleted",
            _matchingData: {
              PeranMenu: {
                id: 73566,
                peran_id: 2,
                menu_id: 321,
              },
            },
            children: [],
          },
        ],
      },
    ],
  },
];

interface MenuItem {
  pages?: MenuItem[];
  sub?: MenuItem[];
}

export const menuList = () => {
  return menu;
};

export const accessMenu = () => {
  const tempMenu = store.getters.getAbility;

  const listMenu: MenuItem[] = [];

  function flattenMenu(menu: MenuItem[]): void {
    for (const item of menu) {
      if (item.sub) {
        flattenMenu(item.sub);
      } else if (item.pages) {
        flattenMenu(item.pages);
      } else {
        listMenu.push(item);
      }
    }
  }

  flattenMenu(tempMenu);

  return listMenu;
};
