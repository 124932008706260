import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class DaftarizinModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.DAFTARIZIN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `izin?q=${data.search}&page=${data.page}&limit=${data.limit}&order=-permohonanizin.id&jenis_permohonan=${data.jenisPermohonan}`
    );
  }

  @Action({ rawError: true })
  [Actions.UPLOADDOKUMENIZIN](data) {
    ApiService.setHeader();
    return ApiService.post(`Izin/upload-file`, data.data);
  }

  @Action({ rawError: true })
  [Actions.CABUTIZIN](data) {
    ApiService.setHeader();
    return ApiService.put(`Izin/izinCabut/${data.id}`, data.data);
  }

  @Action({ rawError: true })
  [Actions.JENISPENGAJUAN]() {
    ApiService.setHeader();
    return ApiService.get(`jenis_pengajuan/jenislist`);
  }

  @Action({ rawError: true })
  [Actions.TIPEPEMOHON]() {
    ApiService.setHeader();
    return ApiService.get(`permohonan_izin/tipepemohonlist`);
  }

  @Action({ rawError: true })
  [Actions.TIPEIDENTITAS]() {
    ApiService.setHeader();
    return ApiService.get(`pemohon/jenisidentitaslist`);
  }

  @Action({ rawError: true })
  [Actions.JENISKELAMIN]() {
    ApiService.setHeader();
    return ApiService.get(`pemohon/genderlist`);
  }

  @Action({ rawError: true })
  [Actions.JENISPROYEK]() {
    ApiService.setHeader();
    return ApiService.get(`PermohonanIzin/getjenisproyeklist`);
  }
}
