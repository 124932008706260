import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class SLAModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.SLAADMIN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `dashboard_sla?q=${data.search}&instansi=${data.instansi}&page=${data.page}&limit=${data.limit}&type=${data.type}&startDate=${data.tanggalAwal}&endDate=${data.tanggalAkhir}`
    );
  }

  @Action({ rawError: true })
  [Actions.SLAADMINDASHBOARD](data) {
    ApiService.setHeader();
    return ApiService.get(
      `sla-dashboard/newKetepatanWaktu?tgl_mulai=${data.tanggalAwal}&tgl_selesai=${data.tanggalAkhir}&instansi_id=${data.instansi}`
    );
  }

  @Action({ rawError: true })
  [Actions.SLAADMINTOPTEN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `sla-dashboard/newTopTenKategori?tgl_mulai=${data.tanggalAwal}&tgl_selesai=${data.tanggalAkhir}&instansi_id=${data.instansi}`
    );
  }

  @Action({ rawError: true })
  [Actions.SLAADMINPERCETASE](data) {
    ApiService.setHeader();
    return ApiService.get(
      `sla-dashboard/persentaseKetepatan?tgl_mulai=${data.tanggalAwal}&tgl_selesai=${data.tanggalAkhir}&instansi_id=${data.instansi}`
    );
  }

  @Action({ rawError: true })
  [Actions.SLAADMINTABLE](data) {
    ApiService.setHeader();
    return ApiService.get(
      `sla-dashboard/newRekapitulasiPerizinan?tgl_mulai=${data.tanggalAwal}&tgl_selesai=${data.tanggalAkhir}&instansi_id=${data.instansi}&limit=${data.limit}&page=${data.page}&q=${data.search}`
    );
  }

  @Action({ rawError: true })
  [Actions.SLAADMINTABLEINTANSI1](data) {
    ApiService.setHeader();
    return ApiService.get(
      `sla-dashboard/newTepatSla?tgl_mulai=${data.tanggalAwal}&tgl_selesai=${data.tanggalAkhir}&instansi_id=${data.instansi}&limit=${data.limit}&page=${data.page}&q=${data.search}`
    );
  }

  @Action({ rawError: true })
  [Actions.SLAADMINTABLEINTANSI2](data) {
    ApiService.setHeader();
    return ApiService.get(
      `sla-dashboard/newTerlambatSla?tgl_mulai=${data.tanggalAwal}&tgl_selesai=${data.tanggalAkhir}&instansi_id=${data.instansi}&limit=${data.limit}&page=${data.page}&q=${data.search}`
    );
  }

  @Action({ rawError: true })
  [Actions.SLAADMINDASHBOARDPERALUR](params) {
    ApiService.setHeader();
    return ApiService.get(`sla-dashboard/${params}`);
  }
}
