export default {
  namespaced: true,
  state: {
    list: {},
    lists: {},
    upload: {},
    tugas: {},
  },
  mutations: {
    SET_LIST(state, valueArr) {
      state.list = valueArr;
    },
    SET_LISTS(state, valueArr) {
      state.lists = valueArr;
    },
    SET_UPLOAD(state, valueArr) {
      state.upload = valueArr;
    },
    SET_TUGAS(state, valueArr) {
      state.tugas = valueArr;
    },
  },
  actions: {
    setList({ commit }, payload) {
      commit("SET_LIST", payload);
    },
    setLists({ commit }, payload) {
      commit("SET_LISTS", payload);
    },
    setUpload({ commit }, payload) {
      commit("SET_UPLOAD", payload);
    },
    setTugas({ commit }, payload) {
      commit("SET_TUGAS", payload);
    },
  },
};
