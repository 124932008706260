import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class FaqModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.FAQ](data) {
    ApiService.setHeader();
    return ApiService.get(
      `faq?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.FAQKATEGORILIST]() {
    ApiService.setHeader();
    return ApiService.get(`faq_category/list?q=`);
  }

  @Action({ rawError: true })
  [Actions.DETAILFAQ](id) {
    ApiService.setHeader();
    return ApiService.get(`faq/${id}`);
  }

  @Action({ rawError: true })
  [Actions.SAVEFAQ](data) {
    ApiService.setHeader();
    return ApiService.post(`faq`, data);
  }

  @Action({ rawError: true })
  [Actions.UBAHFAQ](data) {
    ApiService.setHeader();
    return ApiService.put(`faq/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.UPLOADDOKUMENFAQ](data) {
    ApiService.setHeader();
    return ApiService.post(`/Faq/upload`, data.data);
  }

  @Action({ rawError: true })
  [Actions.DELETEFAQ](id) {
    ApiService.setHeader();
    return ApiService.delete(`faq/${id}`);
  }
}
