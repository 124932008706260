export default [
  // {
  //   path: "/home",
  //   name: "home",
  //   component: () => import("@/views/pemohon/dashboard/DashboardPemohon.vue"),
  // },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/pemohon/profile/index.vue"),
  },
  {
    path: "/profile/pemohon",
    name: "pemohon-daftar-permohonan",
    component: () =>
      import("@/views/pemohon/daftarpermohonan/daftarPermohonan.vue"),
    children: [
      // permohonan
      {
        path: "/profile/pemohon/daftar-permohonan/permohonan",
        name: "pemohon-daftar-permohonan-permohonan",
        component: () =>
          import(
            "@/views/pemohon/daftarpermohonan/layoutpermohonan/permohonan.vue"
          ),
      },
      // perusahaan
      {
        path: "/profile/pemohon/daftar-permohonan/perusahaan",
        name: "pemohon-daftar-permohonan-perusahaan",
        component: () =>
          import(
            "@/views/pemohon/daftarpermohonan/layoutpermohonan/perusahaan.vue"
          ),
      },
      // perizinan
      {
        path: "/profile/pemohon/daftar-permohonan/perizinan",
        name: "pemohon-daftar-permohonan-perizinan",
        component: () =>
          import(
            "@/views/pemohon/daftarpermohonan/layoutpermohonan/perizinan.vue"
          ),
      },
      {
        path: "/profile/pemohon/daftar-permohonan/dokumen",
        name: "pemohon-daftar-permohonan-dokumen",
        component: () =>
          import(
            "@/views/pemohon/daftarpermohonan/layoutpermohonan/Dokumen.vue"
          ),
      },
    ],
  },
  {
    path: "/profile/pemohon/daftar-permohonan/dokumen-detail/:id",
    name: "pemohon-daftar-permohonan-dokumen-form",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/dokumen/Form.vue"
      ),
  },
  {
    path: "/profile/pemohon/daftar-permohonan/dokumen-add",
    name: "pemohon-daftar-permohonan-dokumen-form-add",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/dokumen/Form.vue"
      ),
  },
  // Add Pemohon
  {
    path: "/profile/pemohon/daftar-permohonan/add/permohonan",
    name: "pemohon-daftar-permohonan-add-permohonan",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/permohonan/formTambahPermohonan.vue"
      ),
  },
  {
    path: "/profile/pemohon/permohonan-izin-detail/:id",
    name: "pemohon-daftar-permohonan-detail-permohonan",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/permohonan/detailPermohonan.vue"
      ),
  },
  {
    path: "/profile/pemohon/daftar-permohonan/edit/permohonan/:id",
    name: "pemohon-daftar-permohonan-edit-permohonan",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/permohonan-edit/formEditPermohonan.vue"
      ),
  },
  // Detail Perusahaan
  {
    path: "/profile/pemohon/perusahaan-detail/:id",
    name: "pemohon-daftar-permohonan-detail-perusahaan",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/perusahaan/Detail.vue"
      ),
  },
  // Detail Perizinan
  {
    path: "/profile/pemohon/daftar-permohonan/detail/perizinan/:id",
    name: "pemohon-daftar-permohonan-detail-perizinan",
    component: () =>
      import(
        "@/views/pemohon/daftarpermohonan/layoutpermohonan/layoutForm/perizinan/formTambahPerizinan.vue"
      ),
  },
  // keluhan diambil dari form dinamis admin
  {
    path: "/form/list/:id",
    name: "form-list",
    component: () => import("@/views/admin/templateDynamic/listForm.vue"),
  },
  {
    path: "/form/render/:id",
    name: "form-render",
    component: () => import("@/views/admin/templateDynamic/renderForm.vue"),
  },
  {
    path: "/form/render/:id/:keyid",
    name: "form-render-id",
    component: () => import("@/views/admin/templateDynamic/renderForm.vue"),
  },

  // simulasi tarif
  {
    path: "/simulasi-tarif",
    name: "simulasi-tarif",
    component: () => import("@/views/pemohon/simulasitarif/SimulasiTarif.vue"),
  },
  // daftar aduan di pindah pada index
  // {
  //   path: "/aduan",
  //   name: "aduan",
  //   component: () => import("@/views/pemohon/daftaraduan/DaftarAduan.vue"),
  //   children: [
  //     {
  //       path: "/aduan-detail/:id",
  //       name: "aduan-detail",
  //       component: () =>
  //         import("@/views/pemohon/daftaraduan/component/Detail.vue"),
  //     },
  //   ],
  // },
  {
    path: "/video-tutorial/show",
    name: "video-tutorial-show",
    component: () => import("@/views/pemohon/videotutorial/VideoTutorial.vue"),
  },
];
