import { App } from "vue";
import store from "@/store/index";
import axios from "axios";
import VueAxios from "vue-axios";
import { useStore } from "vuex";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { useToast, TYPE } from "vue-toastification";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_WS;

    let link;
    if (process.env.VUE_APP_NODE_ENV === "production") {
      link = "";
    }

    if (process.env.VUE_APP_NODE_ENV === "staging") {
      link = "/staging-new-ui";
    }

    if (process.env.VUE_APP_NODE_ENV === "development") {
      link = "";
    }

    if (process.env.VUE_APP_NODE_ENV === "sandbox") {
      link = "/sandbox-new-ui";
    }

    // ApiService.vueInstance.axios.interceptors.request.use(ApiService.setHeader);
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        if (response.status !== 200) {
          if (
            response.status === 401 ||
            response.statusText === "Unauthorized"
          ) {
            // JwtService.destroyToken();
          } else if (response.status === 500) {
            JwtService.destroyToken();
            window.location.href = `${link}/500`;
          } else if (response.status === 501) {
            JwtService.destroyToken();
            window.location.href = `${link}/500`;
          } else if (response.status > 502) {
            JwtService.destroyToken();
            window.location.href = `${link}/500`;
          } else if (response.status === 502) {
            const toast = useToast();
            toast("Terjadi masalah pada service yang anda akses", {
              type: TYPE.ERROR,
            });
          }

          if (response.status === 502) {
            const dataError = {
              success: false,
              message: "Terjadi masalah pada service yang anda akses",
              data: null,
            };

            store.dispatch("handleErrorStore/setErrorList", dataError);
          } else {
            store.dispatch("handleErrorStore/setErrorList", response);
          }
        }

        return response;
      },
      (error) => {
        //handling error api auth
        if (
          error.response.config.url === "pengguna/token" ||
          error.response.config.url === "pengguna/profile" ||
          error.response.config.url === "menu/appmenu"
        ) {
          store.dispatch("handleErrorStore/setErrorList", error.response);

          Swal.fire({
            text: "Proses Login gagal, silahkan mencoba kembali",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Kembali",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(function () {
            JwtService.destroyToken();
            window.location.href = `${link}/sign-in`;
          });
        }

        // Handling Eof
        if (error.response.data.message.toLowerCase().includes("eof")) {
          const toast = useToast();
          toast("Terjadi kesalahan saat proses, silahkan dicoba kembali", {
            type: error.response.data.success ? TYPE.SUCCESS : TYPE.ERROR,
          });
        }

        // Unauthorized
        if (
          error.response.data.message ===
          "Terjadi masalah pada service yang anda akses. Silahkan coba kembali"
        ) {
          const toast = useToast();
          toast(error.response.data.message, {
            type: error.response.data.success ? TYPE.SUCCESS : TYPE.ERROR,
          });
        } else if (
          error.response.status === 401 ||
          error.response.statusText === "Unauthorized"
        ) {
          JwtService.destroyToken();

          window.location.href = `${link}/sign-in`;
        } else if (error.response.status === 500) {
          JwtService.destroyToken();

          window.location.href = `${link}/500`;
        } else if (error.response.status === 501) {
          JwtService.destroyToken();

          window.location.href = `${link}/500`;
        } else if (error.response.status > 502) {
          JwtService.destroyToken();

          window.location.href = `${link}/500`;
        } else if (error.response.status === 502) {
          const toast = useToast();
          toast("Terjadi masalah pada service yang anda akses", {
            type: TYPE.ERROR,
          });
        }

        if (error.response.status === 502) {
          const dataError = {
            success: false,
            message: "Terjadi masalah pada service yang anda akses",
            data: null,
          };

          store.dispatch("handleErrorStore/setErrorList", dataError);
        } else {
          store.dispatch("handleErrorStore/setErrorList", error.response);
        }

        return error.response;
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    const datatoken = store.state.appsStore;
    const token = JSON.parse(JSON.stringify(datatoken));

    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token.id_token}`;
    ApiService.vueInstance.axios.defaults.headers.common[
      "Cache-Control"
    ] = `no-cache, no-store, must-revalidate`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
    ApiService.vueInstance.axios.defaults.responseType = "json";
  }

  public static setHeaderAcceptBlob(): void {
    const datatoken = store.state.appsStore;
    const { id_token } = JSON.parse(JSON.stringify(datatoken));
    console.log(datatoken);
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${id_token}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json, text/plain, */*";
    ApiService.vueInstance.axios.defaults.responseType = "blob";
  }

  public static setHeaderAcceptPublic(): void {
    ApiService.vueInstance.axios.defaults.headers.common = {};
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string
    // slug = "" as string
  ): Promise<AxiosResponse> {
    // return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  public static postnotpayload(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  public static updatenotpayload(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
