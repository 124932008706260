export default {
  namespaced: true,
  state: {
    list: {},
    lists: {},
    listsTipeKeluaran: {},
    listsJenisSumber: {},
    listsTipeKelompok: {},
    profile: {},
    query: {},
    listsTipeJoin: {},
    listsTipeKondisi: {},
    listsTipeRelasi: {},
  },
  mutations: {
    SET_LIST(state, valueArr) {
      state.list = valueArr;
    },
    SET_LISTS(state, valueArr) {
      state.lists = valueArr;
    },
    SET_LISTSTIPEKELUARAN(state, valueArr) {
      state.listsTipeKeluaran = valueArr;
    },
    SET_LISTSJENISSUMBER(state, valueArr) {
      state.listsJenisSumber = valueArr;
    },
    SET_LISTSTIPEKELOMPOK(state, valueArr) {
      state.listsTipeKelompok = valueArr;
    },
    SET_PROFILE(state, valueArr) {
      state.profile = valueArr;
    },
    SET_QUERY(state, valueArr) {
      state.query = valueArr;
    },
    SET_LISTSTIPEJOIN(state, valueArr) {
      state.listsTipeJoin = valueArr;
    },
    SET_LISTSTIPEKONDISI(state, valueArr) {
      state.listsTipeKondisi = valueArr;
    },
    SET_LISTSTIPERELASI(state, valueArr) {
      state.listsTipeRelasi = valueArr;
    },
  },
  actions: {
    setList({ commit }, payload) {
      commit("SET_LIST", payload);
    },
    setLists({ commit }, payload) {
      commit("SET_LISTS", payload);
    },
    listsTipeKeluaran({ commit }, payload) {
      commit("SET_LISTSTIPEKELUARAN", payload);
    },
    listsJenisSumber({ commit }, payload) {
      commit("SET_LISTSJENISSUMBER", payload);
    },
    listsTipeKelompok({ commit }, payload) {
      commit("SET_LISTSTIPEKELOMPOK", payload);
    },
    setProfile({ commit }, payload) {
      commit("SET_PROFILE", payload);
    },
    listsQuery({ commit }, payload) {
      commit("SET_QUERY", payload);
    },
    setTipeJoin({ commit }, payload) {
      commit("SET_LISTSTIPEJOIN", payload);
    },
    setTipeKondisi({ commit }, payload) {
      commit("SET_LISTSTIPEKONDISI", payload);
    },
    setTipeRelasi({ commit }, payload) {
      commit("SET_LISTSTIPERELASI", payload);
    },
  },
};
