import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Apis } from "@/store/enums/StoreApp";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class AduanModule extends VuexModule {
  @Action({ rawError: true })
  [Apis.ADUANMODULE](data) {
    ApiService.setHeader();
    if (data.method == "GET") {
      return ApiService.get(`caduan${data.filter}`);
    }

    if (data.method == "POST") {
      return ApiService.post(`caduan${data.filter}`, data.data);
    }

    if (data.method == "PUT") {
      return ApiService.put(`caduan${data.filter}`, data.data);
    }

    if (data.method == "DEL") {
      return ApiService.delete(`caduan${data.filter}`);
    }

    if (data.method == "GETPEGAWAI") {
      return ApiService.get(`pegawai${data.filter}`);
    }
  }
}
