import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ProsedurOperasionalModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETPROSEDUR](data) {
    ApiService.setHeader();
    return ApiService.get(
      `portalProsedurOperasional?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETPROSEDURDETAIL](id) {
    ApiService.setHeader();
    return ApiService.get(`portalProsedurOperasional/view/${id}`);
  }
  @Action({ rawError: true })
  [Actions.SAVEPROSEDUR](data) {
    ApiService.setHeader();
    return ApiService.post(`portalProsedurOperasional/add`, data);
  }
  @Action({ rawError: true })
  [Actions.UPDATEPROSEDUR](data) {
    ApiService.setHeader();
    return ApiService.post(`portalProsedurOperasional/edit/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.DELETEPROSEDUR](id) {
    ApiService.setHeader();
    return ApiService.delete(`portalProsedurOperasional/delete/${id}`);
  }
}
