import ApiService from "@/core/services/ApiService";
import store from "@/store/index";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class DesaModule extends VuexModule {
  @Action
  [Actions.DESA](data) {
    ApiService.setHeader();

    if (data.method === "GETLISTS") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch("desaStore/setLists", data.data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "GETLIST") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch("desaStore/setList", data.data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "POST") {
      return ApiService.post(data.filter, data.data);
    }

    if (data.method === "PUT") {
      return ApiService.put(data.filter, data.data);
    }

    if (data.method === "DELETE") {
      return ApiService.delete(data.filter);
    }
  }
}
