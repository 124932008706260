import ApiService from "@/core/services/ApiService";
import store from "@/store/index";
import JwtService from "@/core/services/JwtService";
import { useToast, TYPE } from "vue-toastification";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { menuList } from "@/core/helpers/menuConfigTemp";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  ability = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  assesment = false;
  penggunaSetting = null;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get getAbility() {
    return this.ability;
  }

  get getAssesment() {
    return this.assesment;
  }

  get getPenggunaSetting() {
    return this.penggunaSetting;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_ABILITY](objAbility) {
    this.ability = objAbility;
  }

  @Mutation
  [Mutations.SET_ASSESMENT](assesment) {
    this.assesment = assesment;
  }

  @Mutation
  [Mutations.SET_PENGGUNA_SETTING](penggunaSetting) {
    this.penggunaSetting = penggunaSetting;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = user.success;
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.data);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    this.ability = [];

    this.assesment = false;
    JwtService.destroyToken();

    store.dispatch("appsStore/setProfile", {});
    store.dispatch("appsStore/setRole", {});
    store.dispatch("appsStore/setAbility", {});
    store.dispatch("appsStore/setToken", {});

    const router = useRouter();
    // router.push({ name: "sign-in" });
  }

  @Action({ rawError: true })
  [Actions.LOGIN](credentials) {
    return ApiService.post("pengguna/token", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        if (data.success === true) {
          store.dispatch("appsStore/setToken", data.data);
          store.dispatch(Actions.PROFILE);
          this.context.dispatch(Actions.MENU);
        }
        this.context.commit(Mutations.SET_ERROR, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action({ rawError: true })
  [Actions.LOGINSSO](credentials) {
    this.context.commit(Mutations.SET_AUTH, credentials.token);
    ApiService.setHeader();
    return ApiService.post("pengguna/tokenOss", credentials)
      .then(({ data }) => {
        if (data.success) {
          store.dispatch(Actions.PROFILE);
          this.context.dispatch(Actions.MENU);
        }
        this.context.commit(Mutations.SET_ERROR, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action({ rawError: true })
  [Actions.SSO](data) {
    return ApiService.post(`pengguna/sso2`, data);
  }

  @Action({ rawError: true })
  [Actions.PROFILE]() {
    ApiService.setHeader();
    return ApiService.get(`pengguna/profile`)
      .then(({ data }) => {
        if (data.success === true) {
          if (process.env.VUE_APP_NODE_ENV === "production") {
            store.dispatch("appsStore/setProfile", data.data);
            store.dispatch("appsStore/setRole", data.data.related_object_name);
          }

          if (
            process.env.VUE_APP_NODE_ENV === "development" ||
            process.env.VUE_APP_NODE_ENV === "staging" ||
            process.env.VUE_APP_NODE_ENV === "sandbox"
          ) {
            JwtService.saveRole(data.data.related_object_name);
            JwtService.saveProfile(data.data);
          }

          if (data.data.peran.id == 17) {
            this.context.dispatch(Actions.CHEKMENUASSESMENT, {
              id: null,
              tampilan: "login",
            });
          }
        }
      })
      .catch(({ response }) => {
        JwtService.destroyToken();

        const toast = useToast();
        toast("Terjadi masalah pada service yang anda akses", {
          type: TYPE.ERROR,
        });

        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.SET_ERROR, response.data.status);
      });
  }

  @Action({ rawError: true })
  [Actions.MENU]() {
    ApiService.setHeader();
    return ApiService.get(`menu/appmenu`)
      .then(({ data }) => {
        if (data.success === true) {
          // store data
          const menu = data.data;
          // const menu = menuList();

          const DocMenuAdminConfig: any = [];
          const pages: any = [];

          // list menu
          const allmenu: any = [];

          for (let i = 0; i < menu.length; i++) {
            if (menu[i].children.length <= 0) {
              const innerObj = {};
              innerObj["heading"] = menu[i].label_menu;
              innerObj["fontIcon"] = menu[i].icon;
              innerObj["route"] = `/${menu[i].tautan}`;

              allmenu.push({
                menuname: menu[i].label_menu,
                menuroute: `/${menu[i].tautan}`,
              });

              pages.push(innerObj);
            } else if (menu[i].children.length > 0) {
              const subMenu: unknown[] = [];
              for (let x = 0; x < menu[i].children.length; x++) {
                if (menu[i].children[x].children.length <= 0) {
                  const innerObjSubMenu = {};
                  innerObjSubMenu["heading"] = menu[i].children[x].label_menu;
                  innerObjSubMenu["fontIcon"] = menu[i].children[x].icon;
                  innerObjSubMenu["route"] = `/${menu[i].children[x].tautan}`;

                  allmenu.push({
                    menuname: menu[i].children[x].label_menu,
                    menuroute: `/${menu[i].children[x].tautan}`,
                  });

                  subMenu.push(innerObjSubMenu);
                } else if (menu[i].children[x].children.length > 0) {
                  const innerObjSubMenu = {};
                  const subMenuSub: unknown[] = [];
                  for (
                    let y = 0;
                    y < menu[i].children[x].children.length;
                    y++
                  ) {
                    const innerObjSubMenuSub = {};
                    innerObjSubMenuSub["heading"] =
                      menu[i].children[x].children[y].label_menu;
                    innerObjSubMenuSub["fontIcon"] =
                      menu[i].children[x].children[y].icon;
                    innerObjSubMenuSub[
                      "route"
                    ] = `/${menu[i].children[x].children[y].tautan}`;
                    subMenuSub.push(innerObjSubMenuSub);

                    allmenu.push({
                      menuname: menu[i].children[x].children[y].label_menu,
                      menuroute: `/${menu[i].children[x].children[y].tautan}`,
                    });
                  }

                  innerObjSubMenu["sectionTitle"] =
                    menu[i].children[x].label_menu;
                  innerObjSubMenu["route"] = menu[i].children[x].tautan;
                  innerObjSubMenu["fontIcon"] = menu[i].children[x].icon;
                  innerObjSubMenu["sub"] = subMenuSub;
                  subMenu.push(innerObjSubMenu);
                }
              }

              const innerObj = {};
              innerObj["sectionTitle"] = menu[i].label_menu;
              innerObj["route"] = menu[i].tautan;
              innerObj["fontIcon"] = menu[i].icon;
              innerObj["sub"] = subMenu;

              pages.push(innerObj);
            }
          }

          const innerObjMenu = {};
          innerObjMenu["pages"] = pages;
          DocMenuAdminConfig.push(innerObjMenu);

          if (process.env.VUE_APP_NODE_ENV === "production") {
            store.dispatch("appsStore/setAbility", DocMenuAdminConfig);
            this.context.commit(Mutations.SET_ABILITY, DocMenuAdminConfig);
          }

          if (
            process.env.VUE_APP_NODE_ENV === "development" ||
            process.env.VUE_APP_NODE_ENV === "staging" ||
            process.env.VUE_APP_NODE_ENV === "sandbox"
          ) {
            JwtService.saveAbility(DocMenuAdminConfig);
            this.context.commit(Mutations.SET_ABILITY, DocMenuAdminConfig);
          }

          this.context.dispatch(Actions.SETTINGPENGGUNA);

          store.dispatch("appsStore/setlist", allmenu);
        }
      })
      .catch(({ response }) => {
        JwtService.destroyToken();

        const toast = useToast();
        toast("Terjadi masalah pada service yang anda akses", {
          type: TYPE.ERROR,
        });

        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.SET_ERROR, response.data.status);
      });
  }

  @Action({ rawError: true })
  [Actions.SETTINGPENGGUNA]() {
    ApiService.setHeader();
    return ApiService.get(`pengguna/setting`)
      .then(({ data }) => {
        if (data.status == true) {
          this.context.commit(
            Mutations.SET_PENGGUNA_SETTING,
            data.data.default_logo
          );
        }
      })
      .catch(({ response }) => {
        JwtService.destroyToken();

        const toast = useToast();
        toast("Terjadi masalah pada service yang anda akses", {
          type: TYPE.ERROR,
        });

        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.SET_ERROR, response.data.status);
      });
  }

  @Action({ rawError: true })
  [Actions.CHEKMENUASSESMENT](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/isAssess/${data.id}?tampil=${data.tampilan}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ASSESMENT, data.data.assess);
      })
      .catch(({ response }) => {
        JwtService.destroyToken();

        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.SET_ERROR, response.data.status);
      });
  }

  @Action({ rawError: true })
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    this.context.commit(Mutations.SET_PENGGUNA_SETTING, null);
  }

  @Action({ rawError: true })
  [Actions.LOGOUTSSO](data) {
    ApiService.setHeader();
    return ApiService.post(`pengguna/ssologout`, data)
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.SET_PENGGUNA_SETTING, null);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.SET_ERROR, response.data.status);
      });
  }

  @Action({ rawError: true })
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  // @Action({ rawError: true })
  // [Actions.FORGOT_PASSWORD](payload) {
  //   return ApiService.post("forgot_password", payload)
  //     .then(() => {
  //       this.context.commit(Mutations.SET_ERROR, {});
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data);
  //     });
  // }

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("menu/validateAccess", payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
