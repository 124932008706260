import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class DashboardStatisticModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.DASHBOARDSTATISTIC]() {
    ApiService.setHeader();
    return ApiService.get("dashboard-statistic");
  }
  @Action({ rawError: true })
  [Actions.GETDATA](data) {
    return ApiService.get(
      `dashboard-statistic/${data.method}?q=${data.search}&page=${
        data.page === undefined ? "" : data.page
      }&limit=${data.limit === undefined ? "" : data.limit}&tipe_pemohon=${
        data.tipe_pemohon === undefined ? "" : data.tipe_pemohon
      }&jenis_permohonan=${
        data.jenis_permohonan === undefined ? "" : data.jenis_permohonan
      }&tab=${data.tab === undefined ? "" : data.tab}`
    );
  }
  @Action({ rawError: true })
  [Actions.JENISIZINSTACKCHART](param) {
    ApiService.setHeader();
    return ApiService.get(
      `dashboard-statistic/grafik-jenis-izin?bulan=${
        param.bulan === null ? "" : param.bulan
      }&tahun=${param.tahun === null ? "" : param.tahun}`
    );
  }

  // export data
  @Action({ rawError: true })
  [Actions.EXPORTPEMOHON](param) {
    ApiService.setHeader();
    return ApiService.get(
      `dashboard-statistic/downloadDataPemohon?q=${param.search}&tipe_pemohon=${param.tipe_pemohon}`
    );
  }

  @Action({ rawError: true })
  [Actions.EXPORTPERMOHONANSTATUS](param) {
    ApiService.setHeader();
    return ApiService.get(
      `dashboard-statistic/permohonan-status-permohonan-export?q=${param.search}&jenis_permohonan=${param.jenis_permohonan}`
    );
  }

  @Action({ rawError: true })
  [Actions.EXPORTPERMOHONANTYPE](param) {
    ApiService.setHeader();
    return ApiService.get(
      `dashboard-statistic/permohonan-type-permohonan-export?q=${param.search}&tipe_pemohon=${param.jenis_permohonan}`
    );
  }

  @Action({ rawError: true })
  [Actions.EXPORTJENISIZIN](param) {
    ApiService.setHeader();
    return ApiService.get(
      `dashboard-statistic/jenis-izin-export?q=${param.search}`
    );
  }

  @Action({ rawError: true })
  [Actions.EXPORTPEGAWAI](param) {
    ApiService.setHeader();
    return ApiService.get(
      `dashboard-statistic/pegawai-export?q=${param.search}`
    );
  }

  @Action({ rawError: true })
  [Actions.EXPORTINSTANSI](param) {
    ApiService.setHeader();
    return ApiService.get(
      `dashboard-statistic/instansi-export?q=${param.search}`
    );
  }

  @Action({ rawError: true })
  [Actions.EXPORTPENGGUNA](param) {
    ApiService.setHeader();
    return ApiService.get(
      `dashboard-statistic/pengguna-export?q=${param.search}`
    );
  }
}
