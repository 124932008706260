import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class LogSenderMailModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.LITSLOGMAIL](data) {
    ApiService.setHeader();
    return ApiService.get(
      `LogEmail?q=${data.search}&limit=${data.limit}&page=${data.page}`
    );
  }

  @Action({ rawError: true })
  [Actions.POSTLOGMAIL](data) {
    ApiService.setHeader();
    return ApiService.post(`LogEmail/tes`, data);
  }
}
