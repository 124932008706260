import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ArtikelModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.ARTIKEL](data) {
    ApiService.setHeader();
    return ApiService.get(
      `portalArtikel?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.KATEGORIARTIKELIST]() {
    ApiService.setHeader();
    return ApiService.get(`portalArtikelKategori/getList?q=`);
  }

  @Action({ rawError: true })
  [Actions.DETAILARTIKEL](id) {
    ApiService.setHeader();
    return ApiService.get(`portalArtikel/view/${id}`);
  }

  @Action({ rawError: true })
  [Actions.SAVEARTIKEL](data) {
    ApiService.setHeader();
    return ApiService.post(`portalArtikel/add`, data);
  }

  @Action({ rawError: true })
  [Actions.UBAHARTIKEL](data) {
    ApiService.setHeader();
    return ApiService.post(`portalArtikel/edit/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.DELETEARTIKEL](id) {
    ApiService.setHeader();
    return ApiService.delete(`portalArtikel/delete/${id}`);
  }
}
