import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ValidasiAkesModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.VALIDASIAKSESMENU](data) {
    let validasiAkses = false;
    ApiService.setHeader();
    ApiService.get(
      `getAccess?tautan=${data.tautan}&path=${data.path}&id=${data.id}`
    )
      .then(({ data }) => {
        validasiAkses = data.data.akses;
      })
      .catch(({ response }) => {
        validasiAkses = data.data.akses;
      });

    return validasiAkses;
  }
}
