import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class AlurProsesModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.ALURPROSES](data) {
    ApiService.setHeader();
    return ApiService.get(
      `alur_proses?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.JENISALURPROSES](data) {
    ApiService.setHeader();
    return ApiService.get(`jenis_proses/list?q=${data.search}&limit=100`);
  }

  @Action({ rawError: true })
  [Actions.TIPEPROSES]() {
    ApiService.setHeader();
    return ApiService.get(`AlurProses/gettipeproses?limit=100`);
  }

  @Action({ rawError: true })
  [Actions.TAUTANPROSES]() {
    ApiService.setHeader();
    return ApiService.get(`alur_proses/tautanlist`);
  }

  @Action({ rawError: true })
  [Actions.FORMPROSES](data) {
    ApiService.setHeader();
    return ApiService.get(`form/getlist?q=${data.search}&limit=100`);
  }

  @Action({ rawError: true })
  [Actions.REPORTPROSES](data) {
    ApiService.setHeader();
    return ApiService.get(
      `TemplateData/getreportlist?q=${data.search}&limit=100`
    );
  }

  @Action({ rawError: true })
  [Actions.DETAILALURPROSES](id) {
    ApiService.setHeader();
    return ApiService.get(`alur_proses/${id}`);
  }

  @Action({ rawError: true })
  [Actions.SAVEALURPROSES](data) {
    ApiService.setHeader();
    return ApiService.post(`alur_proses`, data);
  }

  @Action({ rawError: true })
  [Actions.UBAHALURPROSES](data) {
    ApiService.setHeader();
    return ApiService.put(`alur_proses/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.DUPLICATALURPROSES](id) {
    ApiService.setHeader();
    return ApiService.postnotpayload(`AlurProses/copyalur/${id}`);
  }

  @Action({ rawError: true })
  [Actions.DELETEALURPROSES](id) {
    ApiService.setHeader();
    return ApiService.delete(`alur_proses/${id}`);
  }
}
