import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class JenisDokumenModules extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETJENISDOKUMEN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `jenis_dokumen?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
  @Action({ rawError: true })
  [Actions.POSTJENISDOKUMEN](data) {
    ApiService.setHeader();
    return ApiService.post(`jenis_dokumen`, data);
  }
  @Action({ rawError: true })
  [Actions.DETAILJENISDOKUMEN](id) {
    ApiService.setHeader();
    return ApiService.get(`jenis_dokumen/${id}`);
  }
  @Action({ rawError: true })
  [Actions.DELETEJENISDOKUMEN](id) {
    ApiService.setHeader();
    return ApiService.delete(`jenis_dokumen/${id}`);
  }
  @Action({ rawError: true })
  [Actions.UPDATEJENISDOKUMEN](data) {
    ApiService.setHeader();
    return ApiService.put(`jenis_dokumen/${data.id}`, data);
  }
}
