import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class PenomoranModules extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETPENOMORAN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `penomoran?q=${data.search}&page=${data.page}&limit=${
        data.limit
      }&access=${data.access !== undefined ? data.access : ""}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETPENOMORANDETAIL](id) {
    ApiService.setHeader();
    return ApiService.get(`penomoran/${id}`);
  }
  @Action({ rawError: true })
  [Actions.GETPENOMORANJENISIZIN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `penomoran/getJenisIzin/${data.id}?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETLISTINSTANSI](query) {
    ApiService.setHeader();
    return ApiService.get(`unit/instansilist?q=${query}`);
  }
  @Action({ rawError: true })
  [Actions.GETUNITLIST](query) {
    let params = "";
    if (query != undefined || query != null) {
      params = query;
    } else {
      params = "q=";
    }

    ApiService.setHeader();
    return ApiService.get(`unit/unitlist?${params}`);
  }
  @Action({ rawError: true })
  [Actions.SAVEPENOMORAN](data) {
    ApiService.setHeader();
    return ApiService.post(`penomoran`, data);
  }
  @Action({ rawError: true })
  [Actions.UPDATEPENOMORAN](data) {
    ApiService.setHeader();
    return ApiService.put(`penomoran/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.DELETEPENOMORAN](id) {
    ApiService.setHeader();
    return ApiService.delete(`penomoran/${id}`);
  }
  @Action({ rawError: true })
  [Actions.DELETEDETAILS](id) {
    ApiService.setHeader();
    return ApiService.delete(`Penomoran/deletedetail/${id}`);
  }
}
