import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ArtikelKategoriModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.ARTIKELKATEGORI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `portalArtikelKategori?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.DETAILARTIKELKATEGORI](id) {
    ApiService.setHeader();
    return ApiService.get(`portalArtikelKategori/view/${id}`);
  }

  @Action({ rawError: true })
  [Actions.SAVEARTIKELKATEGORI](data) {
    ApiService.setHeader();
    return ApiService.post(`portalArtikelKategori/add`, data);
  }

  @Action({ rawError: true })
  [Actions.UBAHARTIKELKATEGORI](data) {
    ApiService.setHeader();
    return ApiService.post(`portalArtikelKategori/edit/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.DELETEARTIKELKATEGORI](id) {
    ApiService.setHeader();
    return ApiService.delete(`portalArtikelKategori/delete/${id}`);
  }
}
