import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class GaleriModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETGALERI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `portalGaleri?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETGALERIDETAIL](id) {
    ApiService.setHeader();
    return ApiService.get(`portalGaleri/view/${id}`);
  }
  @Action({ rawError: true })
  [Actions.SAVEGALERI](data) {
    ApiService.setHeader();
    return ApiService.post(`portalGaleri/add`, data);
  }
  @Action({ rawError: true })
  [Actions.UPDATEGALERI](data) {
    ApiService.setHeader();
    return ApiService.post(`portalGaleri/edit/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.DELETEGALERI](id) {
    ApiService.setHeader();
    return ApiService.delete(`portalGaleri/delete/${id}`);
  }
}
