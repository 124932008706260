import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ConcurentModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETBYDATECONCURENT](date) {
    ApiService.setHeader();
    return ApiService.get(`ConcurrentUser/concurrentDate?date=${date}`);
  }

  @Action({ rawError: true })
  [Actions.GETLISTCONCURENT](data) {
    ApiService.setHeader();
    return ApiService.get(
      `ConcurrentUser/concurrent?q=${data.search}&sort=id desc&page=${data.page}&limit=${data.limit}&date=${data.date}&endDate=${data.enddate}`
    );
  }

  @Action({ rawError: true })
  [Actions.EXPORTCONCURENT](data) {
    ApiService.setHeader();
    return ApiService.post(`ConcurrentUser/exportExcel`, data);
  }
}
