export default {
  namespaced: true,
  state: {
    serviceEksternal: {},
  },
  mutations: {
    SET_SERVICE_EKSTERNAL(state, valueArr) {
      state.serviceEksternal = valueArr;
    },
  },
  actions: {
    setServiceEksternal({ commit }, payload) {
      commit("SET_SERVICE_EKSTERNAL", payload);
    },
  },
};
