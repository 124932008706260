import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class JenisIzinKategoriModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.JENISIZINKATEGORI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `jenis_izin_kategori?q=${data.search}&page=${data.page}&limit=${data.limit}&order=-JenisIzinKategori.id`
    );
  }
  @Action({ rawError: true })
  [Actions.GETBPNLIST]() {
    ApiService.setHeader();
    return ApiService.get(`jenis_izin_kategori/listData?q=&mode=bpn`);
  }
  @Action({ rawError: true })
  [Actions.POSTJENISIZINKATEGORI](data) {
    ApiService.setHeader();
    return ApiService.post(`jenis_izin_kategori`, data);
  }
  @Action({ rawError: true })
  [Actions.GETJENISIZINKATEGORI](id) {
    ApiService.setHeader();
    return ApiService.get(`jenis_izin_kategori/${id}`);
  }
  @Action({ rawError: true })
  [Actions.EDITJENISIZINKATEGORI](data) {
    ApiService.setHeader();
    return ApiService.put(`jenis_izin_kategori/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.DELETEJENISIZINKATEGORI](id) {
    ApiService.setHeader();
    return ApiService.delete(`jenis_izin_kategori/${id}`);
  }
}
