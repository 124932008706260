import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { Actions } from "@/store/enums/StoreEnums";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import { accessMenu } from "@/core/helpers/menuConfigTemp";

// get routes
import admin from "./routes/admin";
import pemohon from "./routes/pemohon";

let rolesPemohon = false,
  historyWeb;

if (process.env.VUE_APP_NODE_ENV === "production") {
  const dataRole = store.state.appsStore;
  const { role } = dataRole;
  rolesPemohon = role === "Pemohon" ? true : false;
  historyWeb = createWebHistory("/");
}

if (process.env.VUE_APP_NODE_ENV === "development") {
  const dataLocal = localStorage.getItem("role");
  rolesPemohon = dataLocal === "Pemohon" ? true : false;
  historyWeb = createWebHistory("/");
}

if (process.env.VUE_APP_NODE_ENV === "staging") {
  const dataLocal = localStorage.getItem("role");
  rolesPemohon = dataLocal === "Pemohon" ? true : false;
  historyWeb = createWebHistory("/staging-new-ui");
}

if (process.env.VUE_APP_NODE_ENV === "sandbox") {
  const dataLocal = localStorage.getItem("role");
  rolesPemohon = dataLocal === "Pemohon" ? true : false;
  historyWeb = createWebHistory("/sandbox-new-ui");
}

let urouter;
if (rolesPemohon === true) {
  urouter = pemohon;
} else {
  urouter = admin;
}

const droutes = [
  // Auth
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    meta: { requiresAuth: false },
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentification/SignIn.vue"),
      },
    ],
  },
  {
    path: "/verify/:token",
    name: "verify",
    meta: { requiresAuth: false },
    component: () => import("@/views/authentification/verify.vue"),
  },
  {
    path: "/resend-email-registrasi",
    name: "resend-email-registrasi",
    meta: { requiresAuth: false },
    component: () =>
      import("@/views/authentification/resendEmailRegistrasi.vue"),
  },
  {
    path: "/forgot",
    name: "forgot",
    meta: { requiresAuth: false },
    component: () => import("@/views/authentification/forgotpassword.vue"),
  },
  {
    path: "/reset/:token",
    name: "reset",
    meta: { requiresAuth: false },
    component: () => import("@/views/authentification/resetpassword.vue"),
  },
  {
    path: "/applicant-registration",
    name: "applicant-registration",
    meta: { requiresAuth: false },
    component: () =>
      import("@/views/authentification/Applicantregistration.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: { requiresAuth: false },
    component: () => import("@/views/authentification/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    meta: { requiresAuth: false },
    component: () => import("@/views/authentification/Error500.vue"),
  },
  {
    path: "/qr-result",
    name: "qr-result",
    meta: { requiresAuth: false },
    component: () => import("@/views/authentification/QRDokumenPerizinan.vue"),
  },
  {
    path: "/maintenance",
    name: "maintenance",
    meta: { requiresAuth: false },
    component: () => import("@/views/authentification/Maintenance.vue"),
  },
  {
    path: "/concurent-user",
    name: "concurent-user",
    meta: { requiresAuth: false },
    component: () => import("@/views/authentification/ConcurentUser.vue"),
  },
  {
    path: "/notaccess",
    name: "notaccess",
    meta: { requiresAuth: true },
    component: () => import("@/views/authentification/Notacess.vue"),
  },
  {
    path: "/verify-document",
    name: "verify-document",
    meta: { requiresAuth: false },
    component: () => import("@/views/authentification/verify-document.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    meta: { requiresAuth: true },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/home.vue"),
      },
      {
        path: "/aduan",
        name: "aduan",
        component: () => import("@/views/aduan/index.vue"),
        children: [
          {
            path: "/aduan-detail/:id",
            name: "aduan-detail",
            component: () =>
              import("@/views/pemohon/daftaraduan/component/Detail.vue"),
          },
        ],
      },
      {
        path: "/list-notikasi-user",
        name: "list-notikasi-user",
        component: () => import("@/views/notifikasi/notifikasi.vue"),
      },
      {
        path: "/not-access-page",
        name: "not-access-page",
        component: () => import("@/views/authentification/NotAccessPage.vue"),
      },
      {
        path: "/assessment",
        name: "assessment",
        component: () => import("@/views/pemohon/assessment/assessment.vue"),
      },
      ...urouter,
    ],
  },
];

const routes = droutes,
  webHistory = historyWeb;

const router = createRouter({
  history: webHistory,
  routes,
  linkActiveClass: "active-link",
  linkExactActiveClass: "exact-active-link",
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
  if (to.meta.requiresAuth === true) {
    if (to.name != "sign-in" && !store.getters.isUserAuthenticated) {
      next({ name: "sign-in" });
    } else {
      const result = accessMenu().find((element: any) =>
        to.path.includes(element.route)
      );
      const notCheckedMenu = [
        "form-report-grid",
        "form-report",
        "form-render-id",
        "form-render",
        "form-list",
      ];
      const routeName: any = to.name ? to.name : "";
      if (
        !result &&
        to.name !== "notaccess" &&
        !notCheckedMenu.includes(routeName)
      ) {
        // bypass Hak akses
        next();

        // Hak akses
        // next({ name: "notaccess" });
      } else {
        next();
        if (store.getters.getAbility.length === 0) {
          if (process.env.VUE_APP_NODE_ENV === "production") {
            const datamenu = store.state.appsStore;
            const { ability } = datamenu;

            store.commit(Mutations.SET_ABILITY, ability);
          }

          if (
            process.env.VUE_APP_NODE_ENV === "development" ||
            process.env.VUE_APP_NODE_ENV === "staging" ||
            process.env.VUE_APP_NODE_ENV === "sandbox"
          ) {
            const menu = JSON.parse(localStorage.getItem("ability") || "");
            store.commit(Mutations.SET_ABILITY, menu);
          }
        }

        // get Notif
        const paramsAnnouncement = {
          type: "announcement",
          params: "q=&page=1&limit=10&grouped=F&tipe=announcement&full=T",
        };
        store.dispatch(Actions.GETNOTIFIKASIUSER, paramsAnnouncement);

        const paramsInfo = {
          type: "info",
          params: "q=&page=1&limit=10&grouped=T&tipe=general&full=F",
        };
        store.dispatch(Actions.GETNOTIFIKASIUSER, paramsInfo);
      }
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    // store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    window.scrollTo(0, 0);
  }, 200);
});

router.afterEach(() => {
  store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
});

export default router;
