import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class JenisProsesModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.JENISIZIN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `jenis_izin?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.VALIDASI]() {
    ApiService.setHeader();
    return ApiService.get(`integrasi/dataumum/vld`);
  }

  @Action({ rawError: true })
  [Actions.JENISDOKUMEN](param) {
    ApiService.setHeader();
    return ApiService.get(`jenis_dokumen/list?q=${param.q}&limit=100`);
  }

  @Action({ rawError: true })
  [Actions.JENISVALIDASI](params) {
    ApiService.setHeader();
    return ApiService.get(`serviceEksternal/serviceList?q=${params.kode}`);
  }

  @Action({ rawError: true })
  [Actions.ALURPROSESLIST](params) {
    ApiService.setHeader();
    return ApiService.get(`alur_proses/list?q=${params.q}&limit=100`);
  }

  @Action({ rawError: true })
  [Actions.PENOMORANLIST](params) {
    ApiService.setHeader();
    return ApiService.get(`penomoran/list?format=${params.q}&limit=100`);
  }

  @Action({ rawError: true })
  [Actions.FORMLIST](params) {
    ApiService.setHeader();
    return ApiService.get(`form/getlist?q=${params.q}&limit=100`);
  }

  @Action({ rawError: true })
  [Actions.UNITLIST](param) {
    ApiService.setHeader();
    return ApiService.get(`unit/unitlist?q=${param.q}`);
  }

  @Action({ rawError: true })
  [Actions.JENISDOKUMENLIST](param) {
    ApiService.setHeader();
    return ApiService.get(`jenis_dokumen/list?q=${param.q}&limit=100`);
  }

  @Action({ rawError: true })
  [Actions.JENISIZINLIST](param) {
    ApiService.setHeader();
    return ApiService.get(`JenisIzin/getlist?q=${param.q}`);
  }

  @Action({ rawError: true })
  [Actions.DETAILJENISIZIN](id) {
    ApiService.setHeader();
    return ApiService.get(`jenis_izin/${id}`);
  }

  @Action({ rawError: true })
  [Actions.SAVEJENISIZIN](data) {
    ApiService.setHeader();
    return ApiService.post(`jenis_izin`, data);
  }

  @Action({ rawError: true })
  [Actions.UBAHJENISIZIN](data) {
    ApiService.setHeader();
    return ApiService.put(`jenis_izin/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.DUPLICATJENISIZIN](id) {
    ApiService.setHeader();
    return ApiService.updatenotpayload(`JenisIzin/copy/${id}`);
  }

  @Action({ rawError: true })
  [Actions.DELETEJENISIZIN](data) {
    ApiService.setHeader();
    return ApiService.delete(`jenis_izin/${data.id}?q=${data.params}`);
  }

  @Action({ rawError: true })
  [Actions.UBAHSLAJENISIZIN](data) {
    ApiService.setHeader();
    return ApiService.put(
      `JenisPengajuan/jenisPengajuanIzinSla/${data.id}`,
      data
    );
  }

  @Action({ rawError: true })
  [Actions.DELETEPENGAJUAN](data) {
    ApiService.setHeader();
    return ApiService.delete(`JenisPengajuan/delete/${data.id}`);
  }

  @Action({ rawError: true })
  [Actions.DELETEPERSYARATAN](data) {
    ApiService.setHeader();
    return ApiService.delete(`DokumenPendukung/delete/${data.id}`);
  }

  @Action({ rawError: true })
  [Actions.DELETEUNIT](data) {
    ApiService.setHeader();
    return ApiService.delete(`UnitTerkait/delete/${data.id}`);
  }

  @Action({ rawError: true })
  [Actions.DELETEIZINPARALEL](data) {
    ApiService.setHeader();
    return ApiService.delete(`IzinParalel/delete/${data.id}`);
  }
}
