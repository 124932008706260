import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class NotifikasiModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETNOTIFIKASI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `jenis_izin?q=${data.search}&page=${data.page}&limit=${data.limit}&instansi_id=${data.instansi}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETNOTIFIKASIDETAIL](id) {
    ApiService.setHeader();
    return ApiService.get(`notifikasi/${id}`);
  }
  @Action({ rawError: true })
  [Actions.UPDATENOTIFIKASI](data) {
    ApiService.setHeader();
    return ApiService.put(`notifikasi/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.GETSUPORTDATA]() {
    ApiService.setHeader();
    return ApiService.get(`notifikasi/supportdata`);
  }
  @Action({ rawError: true })
  [Actions.GETLISTSERVICE]() {
    ApiService.setHeader();
    return ApiService.get(`service_eksternal/list-service-eksternal?q=`);
  }
  @Action({ rawError: true })
  [Actions.DELETEDETAIL](id) {
    ApiService.setHeader();
    return ApiService.delete(`/Notifikasi/deleteNotifikasiDetail/${id}`);
  }
  @Action({ rawError: true })
  [Actions.DOWNLOADEXPORTPEMOHON](obj) {
    ApiService.setHeaderAcceptBlob();
    return ApiService.get(obj.tautan);
  }
}
