import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class SurveyModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.SURVEYSAYA](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment?q=${data.search}&page=${data.page}&limit=${data.limit}&status=&kategori=&dibuat=${data.username}&id=${data.id}&peran_id=${data.peranId}`
    );
  }

  @Action({ rawError: true })
  [Actions.SURVEYDAERAH](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/dataDaerah?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.SURVEYPUSAT](data) {
    ApiService.setHeader();
    return ApiService.get(
      `/FormAssessment/listDaerah/${data.username}?q=${data.search}&page=${data.page}&limit=${data.limit}&status=&kategori=&peran_id=${data.peranId}`
    );
  }

  @Action({ rawError: true })
  [Actions.TINGKATKEPUASANMASYARAKAT]() {
    ApiService.setHeader();
    return ApiService.get(`FormAssessment/list-kepuasan-masyarakat`);
  }

  @Action({ rawError: true })
  [Actions.OPTIONSTINGKATKEPUASANMASYARAKAT](key) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/list-option-kepuasan-masyarakat/${key}`
    );
  }

  @Action({ rawError: true })
  [Actions.SAVESURVEYSAYA](data) {
    ApiService.setHeader();
    return ApiService.post(`FormAssessment/add`, data);
  }

  @Action({ rawError: true })
  [Actions.DETAILSURVEYSAYA](id) {
    ApiService.setHeader();
    return ApiService.get(`FormAssessment/getFormAssessment/${id}`);
  }

  @Action({ rawError: true })
  [Actions.DELETEQUESTIONSURVEY](id) {
    ApiService.setHeader();
    return ApiService.delete(`FormAssessment/delete-question/${id}`);
  }

  @Action({ rawError: true })
  [Actions.EDITSURVEYSAYA](data) {
    ApiService.setHeader();
    return ApiService.post(`FormAssessment/edit/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.COPYSURVEYSAYA](data) {
    ApiService.setHeader();
    return ApiService.post(`FormAssessment/copySurvey`, data);
  }

  @Action({ rawError: true })
  [Actions.TAMPILANLIST]() {
    ApiService.setHeader();
    return ApiService.get(`FormAssessment/getTampilList`);
  }

  @Action({ rawError: true })
  [Actions.SURVEYAKTIF](data) {
    ApiService.setHeader();
    return ApiService.post(`FormAssessment/activate`, data);
  }

  @Action({ rawError: true })
  [Actions.SURVEYSTOP](data) {
    ApiService.setHeader();
    return ApiService.post(`FormAssessment/stopEvent`, data);
  }

  @Action({ rawError: true })
  [Actions.DETAILLISTDAERAH](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/listDaerah/${data.params}?q=${data.search}&page=${data.page}&limit=${data.limit}&status=&kategori=&id=${data.profileId}&peran_id=${data.peranId}`
    );
  }

  // Hasil Survey
  @Action({ rawError: true })
  [Actions.HASILSURVEY](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/listPeriode?q=${data.search}&page=${data.page}&limit=${data.limit}&begin_date=${data.tanggalAwal}&end_date=${data.tanggalAkhir}`
    );
  }

  @Action({ rawError: true })
  [Actions.EXPORTSURVEY](data) {
    ApiService.setHeaderAcceptBlob();
    return ApiService.get(
      `FormAssessment/downloadData/${data.surveyId}/${data.id}?output_type=xls&q=${data.search}&limit=${data.limit}&order=-id&page=${data.page}&status=&kategori=`
    );
  }

  @Action({ rawError: true })
  [Actions.RATINGLAYANANINSTANSI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/getPermohonanIzinInstansi?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.RATINGLAYANANADMIN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/getPermohonanIzinAdmin?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.DETAILRATINGLAYANAN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/detailPermohonanIzinInstansi/${data.id}?q=${data.search}&page=${data.page}&limit=${data.limit}&status=`
    );
  }

  @Action({ rawError: true })
  [Actions.DETAILRATINGLAYANANADMIN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/detailPermohonanIzinAdmin/${data.id}?q=${data.search}&page=${data.page}&limit=${data.limit}&status=`
    );
  }

  @Action({ rawError: true })
  [Actions.DETAILLISTRESPONDEN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/getFormAssessmentParticipant/${data.id}/${data.surveyId}?q=${data.search}&page=${data.page}&limit=${data.limit}&begin_date=${data.tanggalAwal}&end_date=${data.tanggalAkhir}&order=-id`
    );
  }

  @Action({ rawError: true })
  [Actions.PARTISIPANTASESSMENT](id) {
    ApiService.setHeader();
    return ApiService.get(
      `FormAssessment/participant-kepuasan-masyarakat/${id}`
    );
  }

  @Action({ rawError: true })
  [Actions.SUMMARYCHART](id) {
    ApiService.setHeader();
    return ApiService.get(`FormAssessment/getSummaryChart/${id}`);
  }
}
