import ApiService from "@/core/services/ApiService";
import store from "@/store/index";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ServiceEksternalModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.SERVICEEKSTERNAL](data) {
    ApiService.setHeader();
    return ApiService.get(
      `service_eksternal?q=${data.search}&page=${data.page}&limit=${data.limit}&instansi_id=${data.instansi}`
    );
  }

  @Action({ rawError: true })
  [Actions.DELETESERVICEEKSTERNAL](id) {
    ApiService.setHeader();
    return ApiService.delete(`service_eksternal/${id}`);
  }

  @Action({ rawError: true })
  [Actions.POSTSERVICEEKSTERNAL](data) {
    ApiService.setHeader();
    return ApiService.post(`service_eksternal`, data);
  }

  @Action({ rawError: true })
  [Actions.DETAILSERVICEEKSTERNAL](id) {
    ApiService.setHeader();
    return ApiService.get(`service_eksternal/${id}`);
  }

  @Action({ rawError: true })
  [Actions.UPDATESERVICEEKSTERNAL](data) {
    ApiService.setHeader();
    return ApiService.put(`service_eksternal/${data.id}`, data);
  }

  // WSE SPLP
  @Action({ rawError: true })
  [Actions.SERVICEEKSTERNALSPLP](data) {
    ApiService.setHeader();
    return ApiService.get(
      `service_eksternal/instansiSPLP?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.SERVICEEKSTERNALSPLPDETAIL](data) {
    ApiService.setHeader();
    return ApiService.get(
      `service_eksternal?q=${data.search}&page=${data.page}&limit=${data.limit}&is_splp=true&&instansi_id=${data.instansi}`
    );
  }
}
