import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class TTEModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.TTE](data) {
    ApiService.setHeader();
    return ApiService.get(
      `PermohonanIzin/ttelist?q=${data.search}&page=${data.page}&limit=${data.limit}&order=-permohonanizin.id${data.queryStatus}`
    );
  }

  @Action({ rawError: true })
  [Actions.ALURPROSESTTE](id) {
    ApiService.setHeader();
    return ApiService.get(`PermohonanIzin/getprosespermohonan/${id}`);
  }

  @Action({ rawError: true })
  [Actions.SIGNPDF](data) {
    ApiService.setHeader();
    return ApiService.post(`TemplateData/signReport?key_id=${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.VALIDASISIGNPDF](data) {
    ApiService.setHeader();
    return ApiService.get(
      `PermohonanIzin/validateTte?jabatan_id=${data.jabatan_id}&proses_permohonan_id=${data.id}`
    );
  }

  @Action({ rawError: true })
  [Actions.CANCELSIGNPDF](data) {
    ApiService.setHeader();
    return ApiService.post(`TemplateData/cancelSignReport`, data);
  }

  @Action({ rawError: true })
  [Actions.SIGNPDFJAMAK](data) {
    ApiService.setHeader();
    return ApiService.post(`TemplateData/multipleSign`, data);
  }

  @Action({ rawError: true })
  [Actions.PROSESPERMOHONAN](id) {
    ApiService.setHeader();
    return ApiService.get(`proses_permohonan/${id}`);
  }

  @Action({ rawError: true })
  [Actions.PREVIEWDOKUMEN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `TemplateData/generateReportPreview/${data.templateDataId}?key_id=${data.permohonanId}`
    );
  }

  @Action({ rawError: true })
  [Actions.DOWNLOADDOKUMEN](nameFile) {
    ApiService.setHeaderAcceptBlob();
    return ApiService.get(
      `Form/downloadFile?filename=${nameFile}&foldername=result&force=F`
    );
  }

  @Action({ rawError: true })
  [Actions.DOWNLOADDOKUMENSIGN](nameFile) {
    ApiService.setHeaderAcceptBlob();
    return ApiService.get(
      `ProsesPermohonan/downloadSignedReport?filename=${nameFile}&foldername=signed&force=F`
    );
  }

  @Action({ rawError: true })
  [Actions.LOGTTE](data) {
    ApiService.setHeader();
    return ApiService.get(
      `LogTte?page=${data.page}&limit=${data.limit}&jenis_tte=${data.jenis_tte}&instansi_id=${data.instansi}&no_permohonan=${data.no_permohonan}&tgl_mulai=${data.tgl_mulai}&tgl_selesai=${data.tgl_akhir}&status=${data.status}`
    );
  }
}
