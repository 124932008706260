import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import store from "@/store";

@Module
export default class NotifikasiUserModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETNOTIFIKASIUSER](dataParams) {
    ApiService.setHeader();
    return ApiService.get(`pesan/notifikasi?${dataParams.params}`).then(
      ({ data }) => {
        if (dataParams.type === "announcement") {
          const dataRes = JSON.parse(JSON.stringify(data.data.items));
          store.dispatch("notifUserStore/setAnnouncement", dataRes);
        } else if (dataParams.type === "info") {
          const dataRes = JSON.parse(JSON.stringify(data.data.items));

          const dataInfo: any = [];
          let jumlahNotif = 0;
          for (const val in dataRes) {
            const nameGroup = val;

            if (dataRes[`${nameGroup}`].length > 0) {
              for (let i = 0; i < dataRes[`${nameGroup}`].length; i++) {
                dataInfo.push(dataRes[`${nameGroup}`][i]);

                if (
                  dataRes[`${nameGroup}`][i].pesan_dibaca === null ||
                  dataRes[`${nameGroup}`][i].pesan_dibaca === "" ||
                  dataRes[`${nameGroup}`][i].pesan_dibaca.length === 0
                ) {
                  jumlahNotif += 1;
                }
              }
            }
          }

          store.dispatch(
            "notifUserStore/setInfo",
            dataInfo.sort((a, b) => b.created_at - a.created_at)
          );
          store.dispatch("notifUserStore/setJumlahInfo", jumlahNotif);
        }
      }
    );
  }

  @Action({ rawError: true })
  [Actions.GETNOTIFIKASIUSERLIST](params) {
    ApiService.setHeader();
    return ApiService.get(`pesan/notifikasi?${params}`);
  }

  @Action({ rawError: true })
  [Actions.READNOTIFIKASI](data) {
    ApiService.setHeader();
    return ApiService.post(`pesan/read`, data);
  }
}
