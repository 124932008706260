export default {
  namespaced: true,
  state: {
    list: {},
    lists: {},
  },
  mutations: {
    SET_LIST(state, valueArr) {
      state.list = valueArr;
    },
    SET_LISTS(state, valueArr) {
      state.lists = valueArr;
    },
  },
  actions: {
    setList({ commit }, payload) {
      commit("SET_LIST", payload);
    },
    setLists({ commit }, payload) {
      commit("SET_LISTS", payload);
    },
  },
};
