import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class KkiModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.KKIKABKOTA](data) {
    ApiService.setHeader();
    return ApiService.get(
      `data-kki/kab-kota?q=${data.search}&limit=${data.limit}&page=${data.page}`
    );
  }

  @Action({ rawError: true })
  [Actions.KKIINSTANSI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `data-kki/instansi?q=${data.search}&limit=${data.limit}&page=${data.page}`
    );
  }

  @Action({ rawError: true })
  [Actions.KKIJENISIZIN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `data-kki/jenis-izin?q=${data.search}&limit=${data.limit}&page=${data.page}`
    );
  }

  @Action({ rawError: true })
  [Actions.KKIPERMOHONANIZIN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `data-kki/permohonan-izin?status=${data.status}&?q=${data.search}&limit=${data.limit}&page=${data.page}`
    );
  }

  @Action({ rawError: true })
  [Actions.KKIPERMOHON](data) {
    ApiService.setHeader();
    return ApiService.get(
      `data-kki/pemohon?q=${data.search}&limit=${data.limit}&page=${data.page}`
    );
  }
}
