enum Actions {
  // Auth
  LOGIN = "login",
  SSO = "sso",
  LOGINSSO = "loginSso",
  LOGOUT = "logout",
  LOGOUTSSO = "logoutSso",
  REGISTER = "register",
  SETTINGPENGGUNA = "settingPengguna",
  // FORGOT_PASSWORD = "forgotPassword",
  VERIFY_AUTH = "verifyAuth",
  CHEKMENUASSESMENT = "checkMenuAssesment",

  // App
  PROFILE = "profile",
  MENU = "menu",

  // Aduan Pemohon
  ADUAN = "aduan",
  ADUANDETAIL = "aduanDetail",
  GETKATEGORILIST = "getKategoriList",
  ADDKOMENTAR = "addKomentar",
  UPLOADDOKUMENADUAN = "uploadDokumenAduan",
  SAVEADUAN = "saveAduan",
  UPDATEADUAN = "updateAduan",

  // alurProses
  ALURPROSES = "alurProses",
  JENISALURPROSES = "jenisAlurProses",
  TIPEPROSES = "tipeProses",
  TAUTANPROSES = "tautanProses",
  FORMPROSES = "formProses",
  REPORTPROSES = "reportProses",
  DETAILALURPROSES = "DetailAlurProses",
  SAVEALURPROSES = "saveAlurProses",
  UBAHALURPROSES = "ubahAlurProses",
  DELETEALURPROSES = "deleteAlurProses",
  DUPLICATALURPROSES = "duplicatAlurProses",

  // jenisProses
  JENISPROSES = "jenisProses",
  DETAILJENISPROSES = "detailJenisProses",
  SAVEJENISPROSES = "saveJenisProses",
  UBAHJENISPROSES = "ubahJenisProses",
  DELETEJENISPROSES = "deleteJenisProses",

  //daftar-menu
  DAFTARMENU = "daftarmenu",
  INSTANSI = "instansi",
  PEGAWAI = "pegawai",
  SIMULASITARIF = "simulasiTarif",
  KAMUSDATA = "kamusData",
  TEMPLATEDATA = "templateData",
  PROVINSI = "provinsi",
  KABUPATEN = "kabupaten",
  KECAMATAN = "kecamatan",
  DESA = "desa",
  PERAN = "peran",
  PENGGUNA = "pengguna",
  ADUANKATEGORI = "aduanKategori",
  DAFTARADUAN = "daftarAduan",
  KALENDER = "kalender",
  SETTINGGRAFIK = "settingGrafik",
  PENGGUNAREST = "penggunaRest",
  PENGGUNASMS = "penggunaSms",
  BIDANGUSAHA = "bidangUsaha",
  JENISUSAHA = "jenisUsaha",

  // Service Eksternal
  SERVICEEKSTERNAL = "serviceEksternal",
  POSTSERVICEEKSTERNAL = "postServiceEksternal",
  DETAILSERVICEEKSTERNAL = "detailServiceEksternal",
  UPDATESERVICEEKSTERNAL = "updateServiceEksternal",
  DELETESERVICEEKSTERNAL = "deleteServiceEksternal",

  // Service Eksternal Splp
  SERVICEEKSTERNALSPLP = "serviceEksternalSplp",
  SERVICEEKSTERNALSPLPDETAIL = "serviceEksternalSplpDetail",

  // Detail Pemohon
  DETAILPEMOHON = "detailPemohon",
  DETAILPEMOHONPERMOHONAN = "detailPemohonPermohonan",
  DETAILPEMOHONPERUSAHAAN = "detailPemohonPerusahaan",
  DETAILPEMOHONPERIZINAN = "detailPemohonPerizinan",
  DETAILPEMOHONDOKUMEN = "detailPemohonDokumen",
  SAVEPERMOHONAN = "savePermohonan",
  UPLOADDOKUMENPEMOHON = "uploadDokumenPemohon",
  PERMOHONANALUR = "alurPermohonan",
  PERMOHONANIZINDETAIL = "permohonanIzinDetail",
  GETINTEGRASIKSWPD = "getIntegrasi",
  GETINTEGRASIKKI = "getIntegrasiKKI",
  DOWNLOADDOKUMENPEMOHON = "downloadDokumenPemohon",
  VIEWDOKUMENPEMOHON = "viewDokumenPemohon",
  UPDATEDOKUMENPEMOHON = "updateDokumenPemohon",
  SAVEDOKUMENPEMOHON = "saveDokumenPemohon",
  DETAILPERUSAHAAN = "detailPerusahaan",

  GETINTEGRASIKPUBLIC = "getIntegrasiPublic",
  PERMOHONANIZINEDITPEMOHON = "permohonanIzinEditPemohon",
  UPDATEPERMOHONANEDITPEMOHON = "updatePermohonanEditPemohon",

  // Permohonan Izin Admin
  GETPERMOHONANIZIN = "getPermohonanIzin",
  GETPEMOHONLIST = "getPermohonanList",
  GETPERUSAHAANLIST = "getPerusahaanList",
  GETPERUSAHAANPEMOHON = "getPerusahaanPemohon",
  UPDATEPERMOHONAN = "updatePermohonanIzin",
  DELETEPERMOHONAN = "deletePermohonanIzin",
  // Penomoran
  GETPENOMORAN = "getPenomoran",
  GETPENOMORANJENISIZIN = "getPenomoranJenisIzin",
  GETLISTINSTANSI = "getListInstansi",
  GETUNITLIST = "getUnitList",
  SAVEPENOMORAN = "savePenomoran",
  GETPENOMORANDETAIL = "getPenomoranDetail",
  UPDATEPENOMORAN = "updatePenomoran",
  DELETEPENOMORAN = "deletePenomoran",
  DELETEDETAILS = "deleteDetails",
  // informasi
  GETINFORMASI = "getInformasi",
  GETINFORMASIDETAIL = "getInformasiDetail",
  SAVEINFORMASI = "saveInformasi",
  UPDATEINFORMASI = "updateInformasi",
  DELETEINFORMASI = "deleteInformasi",
  GETLISTKATEGORI = "getListKategori",

  GETKATEGORIINFORMASI = "getKategoriInformasi",
  GETKATEGORIINFORMASIDETAIL = "getKategoriInformasiDetail",
  SAVEKATEGORIINFORMASI = "saveKategoriInformasi",
  UPDATEKATEGORIINFORMASI = "updateKategoriInformasi",
  DELETEKATEGORIINFORMASI = "deleteKategoriInformasi",

  // Galeri
  GETGALERI = "getGaleri",
  GETGALERIDETAIL = "getGaleriDetail",
  SAVEGALERI = "saveGaleri",
  UPDATEGALERI = "updateGaleri",
  DELETEGALERI = "deleteGaleri",

  // Prosedur Operasional
  GETPROSEDUR = "getProsedur",
  GETPROSEDURDETAIL = "getProsedurDetail",
  SAVEPROSEDUR = "saveProsedur",
  UPDATEPROSEDUR = "updateProsedur",
  DELETEPROSEDUR = "deleteProsedur",

  // Notifikasi
  GETNOTIFIKASI = "getNotifikasi",
  GETNOTIFIKASIDETAIL = "getNotifikasiDetail",
  UPDATENOTIFIKASI = "updateNotifikasi",
  GETSUPORTDATA = "getSupportData",
  GETLISTSERVICE = "getListService",
  DELETEDETAIL = "deleteDetail",
  DOWNLOADEXPORTPEMOHON = "downloadexportpemohon",

  // TTE
  TTE = "tte",
  ALURPROSESTTE = "alurProsesTTE",
  SIGNPDF = "signPDF",
  VALIDASISIGNPDF = "validasiSignPDF",
  SIGNPDFJAMAK = "signPDFJamak",
  CANCELSIGNPDF = "cancelSignPdf",
  PROSESPERMOHONAN = "prosesPermohonan",
  PREVIEWDOKUMEN = "previewDokumen",
  DOWNLOADDOKUMEN = "downloadDokumen",
  DOWNLOADDOKUMENSIGN = "downloadDokumenSign",
  LOGTTE = "logTte",

  // jenis Izin
  JENISIZIN = "jenisIzin",
  DETAILJENISIZIN = "detailJenisIzin",
  SAVEJENISIZIN = "saveJenisIzin",
  UBAHJENISIZIN = "ubahJenisIzin",
  DUPLICATJENISIZIN = "duplicatJenisIzin",
  DELETEJENISIZIN = "deleteJenisIzin",
  JENISDOKUMEN = "jenisDokumen",
  JENISVALIDASI = "jenisValidasi",
  VALIDASI = "validasi",
  ALURPROSESLIST = "alurProsesList",
  PENOMORANLIST = "penomoranList",
  FORMLIST = "formList",
  UNITLIST = "unitList",
  JENISDOKUMENLIST = "jenisDokumenList",
  JENISIZINLIST = "jenisIzinList",
  UBAHSLAJENISIZIN = "ubahSLAJenisIzin",
  DELETEPENGAJUAN = "deletePengajuan",
  DELETEPERSYARATAN = "deletePersyaratan",
  DELETEUNIT = "deleteUnit",
  DELETEIZINPARALEL = "deleteIzinParalel",

  // artikel kategori
  ARTIKELKATEGORI = "artikelKategori",
  DETAILARTIKELKATEGORI = "detailArtikelKategori",
  SAVEARTIKELKATEGORI = "saveArtikelKategori",
  UBAHARTIKELKATEGORI = "ubahArtikelKategori",
  DELETEARTIKELKATEGORI = "deleteArtikelKategori",

  // artikel
  ARTIKEL = "artikel",
  DETAILARTIKEL = "detailArtikel",
  SAVEARTIKEL = "saveArtikel",
  UBAHARTIKEL = "ubahArtikel",
  DELETEARTIKEL = "deleteArtikel",
  KATEGORIARTIKELIST = "kategoriArtikelList",

  // survey
  SURVEYSAYA = "surveySaya",
  SURVEYDAERAH = "surveyDaerah",
  SURVEYPUSAT = "surveyPusat",
  TINGKATKEPUASANMASYARAKAT = "tingkatKepuasanMasyarakat",
  OPTIONSTINGKATKEPUASANMASYARAKAT = "optionsTingkatKepuasanMasyarakat",
  DETAILSURVEYSAYA = "detailSurveySaya",
  SAVESURVEYSAYA = "saveSurveySaya",
  EDITSURVEYSAYA = "editSurveySaya",
  COPYSURVEYSAYA = "copySurveySaya",
  TAMPILANLIST = "tampilanList",
  SURVEYAKTIF = "surveyAktif",
  SURVEYSTOP = "surveyStop",
  DETAILLISTDAERAH = "detailListDaerah",
  HASILSURVEY = "hasilSurvey",
  EXPORTSURVEY = "exportSurvey",
  RATINGLAYANANINSTANSI = "ratingLayananInstansi",
  RATINGLAYANANADMIN = "ratingLayananAdmin",
  DETAILRATINGLAYANAN = "detailRatingLayanan",
  DETAILRATINGLAYANANADMIN = "detailRatingLayananAdmin",
  DETAILLISTRESPONDEN = "detailListResponden",
  DELETEQUESTIONSURVEY = "deleteQuestionSurvey",
  PARTISIPANTASESSMENT = "partisipantAsessment",
  SUMMARYCHART = "summaryChart",

  // PROSES PERMOHONAN
  PROSESPENGAJUAN = "prosesPengajuan",
  DOWNLOADREPORT = "downloadReport",

  // SLA
  SLAADMIN = "slaAdmin",
  SLAADMINDASHBOARD = "slaAdminDashboard",
  SLAADMINTOPTEN = "slaAdminTopTen",
  SLAADMINPERCETASE = "slaAdminPersentase",
  SLAADMINTABLE = "slaAdminTable",
  SLAADMINTABLEINTANSI1 = "slaAdminTableIntansi1",
  SLAADMINTABLEINTANSI2 = "slaAdminTableIntansi2",
  SLAADMINDASHBOARDPERALUR = "slaAdminDashboardPeralur",
  SLAADMINDASHBOARDPERALURTABLE = "slaAdminDashboardPeralurTable",
  SLA = "sla",

  // Permohonan izin ditolak
  PERMOHONANDITOLAK = "permohonanDitolak",

  // Dashboard Statistic
  DASHBOARDSTATISTIC = "dashboardStatistic",
  GETDATA = "getData",
  JENISIZINSTACKCHART = "JenisIzinStackChart",

  // Daftar izin
  DAFTARIZIN = "daftarIzin",
  JENISPENGAJUAN = "jenisPengajuan",
  TIPEPEMOHON = "tipePemohon",
  TIPEIDENTITAS = "tipeIdentitas",
  JENISKELAMIN = "jenisKelamin",
  JENISPROYEK = "jenisProyek",
  UPLOADDOKUMENIZIN = "uploadDokumenIzin",
  CABUTIZIN = "cabutIzin",

  // Daftar Pemohon
  DAFTARPEMOHON = "daftarPemohon",
  STATUSPEMOHON = "statusPemohon",
  CHANGESTATUSPEMOHON = "changeStatusPermohon",
  DELETEPEMOHON = "deletePemohon",
  GETPROVINSI = "getProvinsi",
  GETKABUPATEN = "getKabupaten",
  GETKECAMATAN = "getKecamatan",
  GETDESA = "getDesa",
  UBAHPEMOHONADMIN = "ubahPemohonAdmin",

  // Jenis Dokumen
  GETJENISDOKUMEN = "getJenisDokumen",
  DETAILJENISDOKUMEN = "detailJenisDokumen",
  POSTJENISDOKUMEN = "postJenisDokumen",
  UPDATEJENISDOKUMEN = "updateJenisDokumen",
  DELETEJENISDOKUMEN = "deleteJenisDokumen",

  // next Proses
  NEXTPROSES = "nextProses",

  // faq kategori
  FAQKATEGORI = "faqKategori",
  DETAILFAQKATEGORI = "detailFaqKategori",
  SAVEFAQKATEGORI = "saveFaqKategori",
  UBAHFAQKATEGORI = "ubahFaqKategori",
  DELETEFAQKATEGORI = "deleteFaqKategori",

  // faq setting
  FAQ = "faq",
  FAQKATEGORILIST = "faqKategoriList",
  DETAILFAQ = "detailFaq",
  SAVEFAQ = "saveFaq",
  UBAHFAQ = "ubahFaq",
  DELETEFAQ = "deleteFaq",
  UPLOADDOKUMENFAQ = "uploadDokumenFaq",

  // Verify
  VERIFYCHECKTOKEN = "verifyCheckToken",
  VERIFYTOKEN = "verifyToken",

  // forgot password
  FORGOTPASSWORD = "forgotPassword",
  CEKTOKENRESET = "cekTokenReset",
  RESETPASSWORD = "resetPassword",

  // Assessment
  GETASSESSMENTACTIVE = "getAssessmentActive",
  SAVEASSESSMENTACTIVE = "saveAssessmentActive",

  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  UPDATE_USER = "updateUser",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  // Jenis izin Kategori
  JENISIZINKATEGORI = "jenisIzinKategori",
  POSTJENISIZINKATEGORI = "postJenisIzinKategori",
  GETJENISIZINKATEGORI = "getJenisIzinKategori",
  EDITJENISIZINKATEGORI = "EditJenisIzinKategori",
  DELETEJENISIZINKATEGORI = "deleteJenisIzinKategori",
  GETBPNLIST = "getBpnList",

  // Integrasi KKI
  INTEGRASIKKI = "integrasiKKI",

  // Integrasi KKI
  INTEGRASISISDMK = "integrasiSISDMK",
  POSTINTEGRASISISDMK = "postIntegrasiSISDMK",
  PUTINTEGRASISISDMK = "putIntegrasiSISDMK",
  DELETEINTEGRASISISDMK = "deleteIntegrasiSISDMK",

  // SLIDER
  GETSLIDER = "getSlider",
  GETSLIDERDETAIL = "getSliderDetail",
  SAVESLIDER = "saveSlider",
  UPDATESLIDER = "updateSlider",
  DELETESLIDER = "deleteSlider",

  // Notifikasi User
  GETNOTIFIKASIUSER = "getNotifikasUser",
  GETNOTIFIKASIUSERLIST = "getNotifikasUserList",
  READNOTIFIKASI = "readNotifikasi",

  // Rest Service
  GETRESTSERVICES = "gerRestServices",
  SAVERESTSERVICE = "saveRestService",
  DELETERESTSERVICE = "deleteService",
  GETINSTANSILIST = "getInstansiList",
  GETDATATABELLIST = "getDatatabelList",
  UPDATERESTSERVICE = "updateRestService",

  KKIKABKOTA = "kkiKabKota",
  KKIINSTANSI = "kkiInstansi",
  KKIJENISIZIN = "kkiJenisIzin",
  KKIPERMOHONANIZIN = "kkiPermohonanIzin",
  KKIPERMOHON = "kkiPermohonan",

  // rating
  RATINGPERIZINAN = "ratingPerizinan",
  GETFILEPERIZINAN = "getFilePerizinan",

  // validasi
  VALIDASIAKSESMENU = "validasiAkesMenu",

  // penolakan permohonan
  UPLOADDOKUMENPENOLAKAN = "uploadDokumenPenolakan",
  PERMOHONANIZINDITOLAK = "permohonanIzinDitolak",
  DETAILPENOLAKAN = "detailPenolakan",
  LOGMMB = "logMmb",

  EXPORTPEMOHON = "exportPemohon",
  EXPORTPERMOHONANSTATUS = "exportPermohonanStatus",
  EXPORTPERMOHONANTYPE = "exportPermohonanType",
  EXPORTJENISIZIN = "exportJenisIzin",
  EXPORTPEGAWAI = "exportPegawai",
  EXPORTINSTANSI = "exportInstansi",
  EXPORTPENGGUNA = "exportPengguna",

  // Log Mail
  LITSLOGMAIL = "ListlLogMail",
  POSTLOGMAIL = "postlLogMail",

  // Concurent User
  GETBYDATECONCURENT = "getByDateConcurent",
  GETLISTCONCURENT = "getListConcurent",
  EXPORTCONCURENT = "ExportDateConcurent",

  // Table
  GETTABLE = "getTable",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_ABILITY = "setAbility",
  SET_ASSESMENT = "setAssesment",
  SET_PENGGUNA_SETTING = "setPenggunaSetting",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  // Apps Mutations
  SET_ALURPROSES = "setAlurProses",
}

export { Actions, Mutations };
