export default {
  namespaced: true,
  state: {
    announcement: [],
    info: [],
    jumlahinfo: 0,
  },
  mutations: {
    SET_ANNOUNCEMENT(state, valueArr) {
      state.announcement = valueArr;
    },
    SET_INFO(state, valueArr) {
      state.info = valueArr;
    },
    SET_JUMLAH_INFO(state, valueArr) {
      state.jumlahinfo = valueArr;
    },
  },
  actions: {
    setAnnouncement({ commit }, payload) {
      commit("SET_ANNOUNCEMENT", payload);
    },
    setInfo({ commit }, payload) {
      commit("SET_INFO", payload);
    },
    setJumlahInfo({ commit }, payload) {
      commit("SET_JUMLAH_INFO", payload);
    },
  },
};
