import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class PenomoranModules extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETINFORMASI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `portalInformasi?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETLISTKATEGORI]() {
    ApiService.setHeader();
    return ApiService.get(`portalInformasiKategori/getList?q=null`);
  }
  @Action({ rawError: true })
  [Actions.GETINFORMASIDETAIL](id) {
    ApiService.setHeader();
    return ApiService.get(`portalInformasi/view/${id}`);
  }
  @Action({ rawError: true })
  [Actions.SAVEINFORMASI](data) {
    ApiService.setHeader();
    return ApiService.post(`portalInformasi/add`, data);
  }
  @Action({ rawError: true })
  [Actions.UPDATEINFORMASI](data) {
    ApiService.setHeader();
    return ApiService.post(`portalInformasi/edit/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.DELETEINFORMASI](id) {
    ApiService.setHeader();
    return ApiService.delete(`portalInformasi/delete/${id}`);
  }
  @Action({ rawError: true })
  [Actions.GETKATEGORIINFORMASI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `portalInformasiKategori?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETKATEGORIINFORMASIDETAIL](id) {
    ApiService.setHeader();
    return ApiService.get(`portalInformasiKategori/view/${id}`);
  }
  @Action({ rawError: true })
  [Actions.SAVEKATEGORIINFORMASI](data) {
    ApiService.setHeader();
    return ApiService.post(`portalInformasiKategori/add`, data);
  }
  @Action({ rawError: true })
  [Actions.UPDATEKATEGORIINFORMASI](data) {
    ApiService.setHeader();
    return ApiService.post(`portalInformasiKategori/edit/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.DELETEKATEGORIINFORMASI](id) {
    ApiService.setHeader();
    return ApiService.delete(`portalInformasiKategori/delete/${id}`);
  }
}
