import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class DaftarPemohonModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.DAFTARPEMOHON](data) {
    ApiService.setHeader();
    return ApiService.get(
      `pemohon?q=${data.search}&page=${data.page}&limit=${data.limit}&order=asc&data_status=${data.status}`
    );
  }

  @Action({ rawError: true })
  [Actions.STATUSPEMOHON]() {
    ApiService.setHeader();
    return ApiService.get(`pemohon/listStatusPemohon`);
  }

  @Action({ rawError: true })
  [Actions.CHANGESTATUSPEMOHON](data) {
    ApiService.setHeader();
    return ApiService.put(`pemohon/changeStatus/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.DELETEPEMOHON](id) {
    ApiService.setHeader();
    return ApiService.delete(`pemohon/${id}`);
  }

  @Action({ rawError: true })
  [Actions.GETPROVINSI](data) {
    ApiService.setHeader();
    return ApiService.get(`provinsi/list?q=${data.search}`);
  }

  @Action({ rawError: true })
  [Actions.GETKABUPATEN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `kabupaten/list?q=${data.search}&provinsi_id=${data.provinsi}`
    );
  }

  @Action({ rawError: true })
  [Actions.GETKECAMATAN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `kecamatan/list?q=${data.search}&kabupaten_id=${data.kabupaten}`
    );
  }

  @Action({ rawError: true })
  [Actions.GETDESA](data) {
    ApiService.setHeader();
    return ApiService.get(
      `desa/list?q=${data.search}&kecamatan_id=${data.kecamatan}`
    );
  }

  @Action({ rawError: true })
  [Actions.UBAHPEMOHONADMIN](data) {
    ApiService.setHeader();
    return ApiService.put(`pemohon/${data.id}`, data);
  }
}
