export default {
  namespaced: true,
  state: {
    id_token: null,
    profile: {},
    ability: {},
    role: null,
    routerParams: {},
    listmenu: [],
  },
  mutations: {
    SET_TOKEN(state, valueArr) {
      state.id_token = valueArr;
    },
    SET_PROFILE(state, valueArr) {
      state.profile = valueArr;
    },
    SET_ABILITY(state, valueArr) {
      state.ability = valueArr;
    },
    SET_ROLE(state, valueArr) {
      state.role = valueArr;
    },
    SET_ROUTER_PARAMS(state, valueArr) {
      state.routerParams = valueArr;
    },
    SET_LIST_MENU(state, valueArr) {
      state.listmenu = valueArr;
    },
  },
  actions: {
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    setProfile({ commit }, payload) {
      commit("SET_PROFILE", payload);
    },
    setAbility({ commit }, payload) {
      commit("SET_ABILITY", payload);
    },
    setRole({ commit }, payload) {
      commit("SET_ROLE", payload);
    },
    setRouterParams({ commit }, payload) {
      commit("SET_ROUTER_PARAMS", payload);
    },
    setlist({ commit }, payload) {
      commit("SET_LIST_MENU", payload);
    },
  },
};
