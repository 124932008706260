import ApiService from "@/core/services/ApiService";
import { Apis } from "@/store/enums/StoreApp";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class FormModule extends VuexModule {
  @Action({ rawError: true })
  [Apis.FORMMODULE](data) {
    ApiService.setHeader();
    if (data.method == "GET") {
      return ApiService.get(`form${data.filter}`);
    }

    if (data.method == "POST") {
      return ApiService.post(`form${data.filter}`, data.data);
    }

    if (data.method == "PUT") {
      return ApiService.put(`form${data.filter}`, data.data);
    }

    if (data.method == "DEL") {
      return ApiService.delete(`form${data.filter}`);
    }

    if (data.method == "GETEKSTERNAL") {
      return ApiService.get(`service_eksternal${data.filter}`);
    }

    if (data.method == "GETDATATABEL") {
      return ApiService.get(`datatabel${data.filter}`);
    }

    if (data.method == "GETTAMPLATEDATA") {
      return ApiService.get(`TemplateData${data.filter}`);
    }

    if (data.method == "GETTAMPLATEDATA2") {
      return ApiService.get(`template_data${data.filter}`);
    }

    if (data.method == "GETKELOMPOKDATA") {
      return ApiService.get(`KelompokData${data.filter}`);
    }

    if (data.method == "GETKELOMPOKDATA2") {
      return ApiService.get(`kelompok_data${data.filter}`);
    }

    if (data.method == "GETPENOMORAN") {
      return ApiService.get(`Penomoran${data.filter}`);
    }

    if (data.method == "GETPENOMORAN2") {
      return ApiService.get(`penomoran${data.filter}`);
    }
  }

  @Action({ rawError: true })
  [Apis.UPLOADFILE](data) {
    ApiService.setHeader();
    return ApiService.post(`form/uploadFile`, data);
  }

  @Action({ rawError: true })
  [Apis.DOWNLOADFILE](filter) {
    ApiService.setHeader();
    return ApiService.get(`Form/downloadFile${filter}`);
  }
}
