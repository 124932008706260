export default {
  namespaced: true,
  state: {
    setDetailPemohon: {},
    setDetailPemohonPermohonan: {},
    setDetailPemohonPerusahaan: {},
    setDetailPemohonPerizinan: {},
    setDetailPemohonDokumen: {},
  },
  mutations: {
    SET_DETAIL_PEMOHON(state, valueArr) {
      state.setDetailPemohon = valueArr;
    },
    SET_DETAIL_PEMOHON_PERMOHONAN(state, valueArr) {
      state.setDetailPemohonPermohonan = valueArr;
    },
    SET_DETAIL_PEMOHON_PERUSAHAAN(state, valueArr) {
      state.setDetailPemohonPerusahaan = valueArr;
    },
    SET_DETAIL_PEMOHON_PERIZINAN(state, valueArr) {
      state.setDetailPemohonPerizinan = valueArr;
    },
    SET_DETAIL_PEMOHON_DOKUMEN(state, valueArr) {
      state.setDetailPemohonDokumen = valueArr;
    },
  },
  actions: {
    setDetailPemohon({ commit }, payload) {
      commit("SET_DETAIL_PEMOHON", payload);
    },
    setDetailPemohonPermohonan({ commit }, payload) {
      commit("SET_DETAIL_PEMOHON_PERMOHONAN", payload);
    },
    setDetailPemohonPerusahaan({ commit }, payload) {
      commit("SET_DETAIL_PEMOHON_PERUSAHAAN", payload);
    },
    setDetailPemohonPerizinan({ commit }, payload) {
      commit("SET_DETAIL_PEMOHON_PERIZINAN", payload);
    },
    setDetailPemohonDokumen({ commit }, payload) {
      commit("SET_DETAIL_PEMOHON_DOKUMEN", payload);
    },
  },
};
