import ApiService from "@/core/services/ApiService";
import { Apis } from "@/store/enums/StoreApp";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class TarifModule extends VuexModule {
  @Action({ rawError: true })
  [Apis.TARIFMODULE](data) {
    ApiService.setHeader();
    if (data.method == "GET") {
      return ApiService.get(`tarif_item${data.filter}`);
    }

    if (data.method == "POST") {
      return ApiService.post(`tarif_item${data.filter}`, data.data);
    }

    if (data.method == "PUT") {
      return ApiService.put(`tarif_item${data.filter}`, data.data);
    }

    if (data.method == "DEL") {
      return ApiService.delete(`tarif_item${data.filter}`);
    }

    if (data.method == "POSTFORMULA") {
      return ApiService.post(`FormulaRetribusi${data.filter}`, data.data);
    }

    if (data.method == "DELHARGA") {
      return ApiService.delete(`TarifItem${data.filter}`);
    }

    if (data.method == "GETIZIN") {
      return ApiService.get(`jenis_izin${data.filter}`);
    }
  }
}
