import ApiService from "@/core/services/ApiService";
import { Apis } from "@/store/enums/StoreApp";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ProsesNib extends VuexModule {
  @Action({ rawError: true })
  [Apis.PROSESNIB](data) {
    ApiService.setHeader();
    if (data.method == "GET") {
      return ApiService.get(`permohonan_izin${data.filter}`);
    }

    if (data.method == "POST") {
      return ApiService.post(`permohonan_izin${data.filter}`, data.data);
    }

    if (data.method == "PUT") {
      return ApiService.put(`permohonan_izin${data.filter}`, data.data);
    }

    if (data.method == "DEL") {
      return ApiService.delete(`permohonan_izin${data.filter}`);
    }

    if (data.method == "GETPROSES") {
      return ApiService.get(`PermohonanIzin${data.filter}`);
    }

    if (data.method == "POSTNEXT") {
      return ApiService.post(`PermohonanIzin${data.filter}`, data.data);
    }
  }
}
