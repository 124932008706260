import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class PermohonanDitolakModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.PERMOHONANDITOLAK](data) {
    ApiService.setHeader();
    return ApiService.get(
      `PermohonanIzin/permohonanditolak?q=${data.search}&page=${data.page}&limit=${data.limit}&order=-permohonanizin.id`
    );
  }

  @Action({ rawError: true })
  [Actions.DOWNLOADREPORT](data) {
    ApiService.setHeader();
    return ApiService.get(
      `TemplateData/generateReport/${data.id}?key_id=${data.permohonanIzin}`
    );
  }

  @Action({ rawError: true })
  [Actions.DETAILPENOLAKAN](id) {
    ApiService.setHeader();
    return ApiService.get(`permohonan_izin/detailTolakpermohonan/${id}`);
  }

  @Action({ rawError: true })
  [Actions.LOGMMB](data) {
    ApiService.setHeader();
    return ApiService.get(
      `permohonan_izin/logMmb/${data.id}?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
}
