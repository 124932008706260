import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class IntegrasiKKIModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.INTEGRASIKKI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `integrasiKki/getlist?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
}
