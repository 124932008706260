import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class AduanPemohonModules extends VuexModule {
  @Action({ rawError: true })
  [Actions.ADUAN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `caduan?q=${data.search}&page=${data.page}&limit=${data.limit}&status=${data.status}&kategori=${data.kategori}`
    );
  }
  @Action({ rawError: true })
  [Actions.ADUANDETAIL](id) {
    ApiService.setHeader();
    return ApiService.get(`caduan/${id}`);
  }
  @Action({ rawError: true })
  [Actions.GETKATEGORILIST]() {
    ApiService.setHeader();
    return ApiService.get(`caduan/getKategoriList?q=`);
  }
  @Action({ rawError: true })
  [Actions.ADDKOMENTAR](data) {
    ApiService.setHeader();
    return ApiService.post(`caduan/addKomentar/${data.c_aduan_id}`, data);
  }
  @Action({ rawError: true })
  [Actions.UPLOADDOKUMENADUAN](data) {
    ApiService.setHeader();
    return ApiService.post(`caduan/uploadFile`, data);
  }
  @Action({ rawError: true })
  [Actions.SAVEADUAN](data) {
    ApiService.setHeader();
    return ApiService.post(`caduan`, data);
  }
  @Action({ rawError: true })
  [Actions.UPDATEADUAN](data) {
    ApiService.setHeader();
    return ApiService.put(`caduan/editAduan/${data.id}`, data);
  }
}
