import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class IntegrasiSISDMKModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.INTEGRASISISDMK](query) {
    ApiService.setHeader();
    return ApiService.get(`IntegrasiSisdmk/listDataKesehatan?q=${query}`);
  }

  @Action({ rawError: true })
  [Actions.POSTINTEGRASISISDMK](data) {
    ApiService.setHeader();
    return ApiService.post(`IntegrasiSisdmk/add`, data);
  }

  @Action({ rawError: true })
  [Actions.PUTINTEGRASISISDMK](data) {
    ApiService.setHeader();
    return ApiService.put(`IntegrasiSisdmk/edit/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.DELETEINTEGRASISISDMK](id) {
    ApiService.setHeader();
    return ApiService.delete(`IntegrasiSisdmk/delete/${id}`);
  }
}
