export default {
  namespaced: true,
  state: {
    list: {},
    lists: {},
    jabatan: {},
    unit: {},
    instansi: {},
  },
  mutations: {
    SET_LIST(state, valueArr) {
      state.list = valueArr;
    },
    SET_LISTS(state, valueArr) {
      state.lists = valueArr;
    },
    SET_JABATAN(state, valueArr) {
      state.jabatan = valueArr;
    },
    SET_UNIT(state, valueArr) {
      state.unit = valueArr;
    },
    SET_INSTANSI(state, valueArr) {
      state.instansi = valueArr;
    },
  },
  actions: {
    setList({ commit }, payload) {
      commit("SET_LIST", payload);
    },
    setLists({ commit }, payload) {
      commit("SET_LISTS", payload);
    },
    setJabatan({ commit }, payload) {
      commit("SET_JABATAN", payload);
    },
    setUnit({ commit }, payload) {
      commit("SET_UNIT", payload);
    },
    setInstansi({ commit }, payload) {
      commit("SET_INSTANSI", payload);
    },
  },
};
