enum Globals {
  DATEPICKER = "DD-MM-YYYY",
  DATETIMEPICKER = "YYYY-MM-DD HH:mm",
  TYPEFORM = "form",
  TYPETABLE = "tabel",
  TYPETAB = "tab",
  STATICTABLE = "tabel-statik",
  GRIDTABLE = "tabel-grid",
  DOKCETAK = "Dokumen Cetak",
  GRIDNO = 1,
  GRIDLIMIT = 10,
  GETFILE = "https://ws2.sicantik.go.id/sic-k8s/docapi/",
  HTTPPARAM = "httpBuildQuery",
}

enum Apis {
  FORMMODULE = "form",
  COMBOGRID = "template-data/combogrid",
  UPLOADFILE = "form/uploadFile",
  REGPEMOHON = "pemohon",
  DOWNLOADFILE = "Form/downloadFile",
  VIDEOMODULE = "video",
  TARIFMODULE = "tarif",
  ADUANMODULE = "caduan",
  PROSESNIB = "proses-nib",
  REPCOMPONENT = "report-component",
}

export { Globals, Apis };
