import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class DetailPemohonModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.DETAILPEMOHON](data) {
    ApiService.setHeader();

    if (data.akses === "admin") {
      return ApiService.get(`pemohon/view/${data.id}`);
    } else {
      return ApiService.get("pemohon/view/");
    }
  }
  @Action({ rawError: true })
  [Actions.DETAILPEMOHONPERMOHONAN](data) {
    ApiService.setHeader();

    if (data.akses === "admin") {
      return ApiService.get(
        `permohonan_izin?q=${data.search}&page=${data.page}&limit=${data.limit}&begin_date=${data.begin_date}&end_date=${data.end_date}&order=-permohonanizin.id&useSlaList=1&useSlaListIzin=1&pemohon_id=${data.pemohonId}`
      );
    } else {
      return ApiService.get(
        `permohonan_izin?q=${data.search}&page=${data.page}&limit=${data.limit}&begin_date=${data.begin_date}&end_date=${data.end_date}&order=-permohonanizin.id&useSlaList=1&useSlaListIzin=1`
      );
    }
  }
  @Action({ rawError: true })
  [Actions.DETAILPEMOHONPERUSAHAAN](data) {
    ApiService.setHeader();

    if (data.akses === "admin") {
      return ApiService.get(
        `perusahaan?q=${data.search}&page=${data.page}&limit=${data.limit}&order=asc&pemohon_id=${data.pemohonId}`
      );
    } else {
      return ApiService.get(
        `perusahaan?q=${data.search}&page=${data.page}&limit=${data.limit}&order=asc`
      );
    }
  }
  @Action({ rawError: true })
  [Actions.DETAILPEMOHONPERIZINAN](data) {
    ApiService.setHeader();

    if (data.akses === "admin") {
      return ApiService.get(
        `izin?q=${data.search}&page=${data.page}&limit=${data.limit}&order=-izin.id&pemohon_id=${data.pemohonId}`
      );
    } else {
      return ApiService.get(
        `izin?q=${data.search}&page=${data.page}&limit=${data.limit}&order=-izin.id`
      );
    }
  }
  @Action({ rawError: true })
  [Actions.DETAILPEMOHONDOKUMEN](data) {
    ApiService.setHeader();

    if (data.akses === "admin") {
      return ApiService.get(
        `dokumen_pemohon?q=${data.search}&page=${data.page}&limit=${data.limit}&order=asc&pemohon_id=${data.pemohonId}`
      );
    } else {
      return ApiService.get(
        `dokumen_pemohon?q=${data.search}&page=${data.page}&limit=${data.limit}&order=asc`
      );
    }
  }
  @Action({ rawError: true })
  [Actions.SAVEPERMOHONAN](data) {
    ApiService.setHeader();
    return ApiService.post(`permohonan_izin/`, data);
  }
  @Action({ rawError: true })
  [Actions.UPLOADDOKUMENPEMOHON](data) {
    ApiService.setHeader();
    return ApiService.post(`DokumenPemohon/upload`, data);
  }
  @Action({ rawError: true })
  [Actions.PERMOHONANALUR](id) {
    ApiService.setHeader();
    return ApiService.get(`PermohonanIzin/getprosespermohonan/${id}`);
  }
  @Action({ rawError: true })
  [Actions.PERMOHONANIZINDETAIL](id) {
    ApiService.setHeader();
    return ApiService.get(`permohonan_izin/${id}`);
  }
  @Action({ rawError: true })
  [Actions.DOWNLOADDOKUMENPEMOHON](nameFile) {
    // ApiService.setHeader();
    return ApiService.get(
      `Form/downloadFile?filename=${nameFile}&foldername=dokumen_pemohon&force=F`
    );
  }
  @Action({ rawError: true })
  [Actions.VIEWDOKUMENPEMOHON](id) {
    ApiService.setHeader();
    return ApiService.get(`dokumen_pemohon/${id}`);
  }
  @Action({ rawError: true })
  [Actions.UPDATEDOKUMENPEMOHON](data) {
    ApiService.setHeader();
    return ApiService.put(`dokumen_pemohon/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.SAVEDOKUMENPEMOHON](data) {
    ApiService.setHeader();
    return ApiService.post(`dokumen_pemohon`, data);
  }
  @Action({ rawError: true })
  [Actions.DETAILPERUSAHAAN](id) {
    ApiService.setHeader();
    return ApiService.get(`perusahaan/${id}`);
  }

  // edit permohonan pemohon
  @Action({ rawError: true })
  [Actions.PERMOHONANIZINEDITPEMOHON](id) {
    ApiService.setHeader();
    return ApiService.get(`permohonan_izin/${id}`);
  }

  @Action({ rawError: true })
  [Actions.UPDATEPERMOHONANEDITPEMOHON](data) {
    ApiService.setHeader();
    return ApiService.put(`permohonan_izin/${data.id}`, data);
  }
}
