export default {
  namespaced: true,
  state: {
    hierarchy: {},
    list: {},
    lists: {},
    daerahlists: {},
    tipelists: {},
  },
  mutations: {
    SET_HIERARCHY(state, valueArr) {
      state.hierarchy = valueArr;
    },
    SET_LIST(state, valueArr) {
      state.list = valueArr;
    },
    SET_LISTS(state, valueArr) {
      state.lists = valueArr;
    },
    SET_DAERAHLISTS(state, valueArr) {
      state.daerahlists = valueArr;
    },
    SET_TIPELISTS(state, valueArr) {
      state.tipelists = valueArr;
    },
  },
  actions: {
    setHierarchy({ commit }, payload) {
      commit("SET_HIERARCHY", payload);
    },
    setList({ commit }, payload) {
      commit("SET_LIST", payload);
    },
    setLists({ commit }, payload) {
      commit("SET_LISTS", payload);
    },
    setDaerahLists({ commit }, payload) {
      commit("SET_DAERAHLISTS", payload);
    },
    setTipeLists({ commit }, payload) {
      commit("SET_TIPELISTS", payload);
    },
  },
};
