import ApiService from "@/core/services/ApiService";
import { Apis } from "@/store/enums/StoreApp";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ComboGrid extends VuexModule {
  @Action({ rawError: true })
  [Apis.COMBOGRID](filter) {
    ApiService.setHeader();
    return ApiService.get(`template-data/combogrid${filter}`);
  }
}
