import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class VerifyTokenModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.VERIFYCHECKTOKEN](data) {
    ApiService.setHeader();
    return ApiService.post(`pemohon/checkverifytoken`, data);
  }

  @Action({ rawError: true })
  [Actions.VERIFYTOKEN](data) {
    ApiService.setHeader();
    return ApiService.post(`pemohon/verify`, data);
  }

  @Action({ rawError: true })
  [Actions.FORGOTPASSWORD](data) {
    ApiService.setHeader();
    return ApiService.post(`pengguna/forgotpassword`, data);
  }
  @Action({ rawError: true })
  [Actions.CEKTOKENRESET](data) {
    ApiService.setHeader();
    return ApiService.post(`pengguna/checkresettoken`, data);
  }
  @Action({ rawError: true })
  [Actions.RESETPASSWORD](data) {
    ApiService.setHeader();
    return ApiService.post(`pengguna/resetpassword`, data);
  }
}
