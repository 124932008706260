import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class RatingPerizinan extends VuexModule {
  @Action({ rawError: true })
  [Actions.RATINGPERIZINAN](data) {
    ApiService.setHeader();
    return ApiService.post(`PermohonanIzin/edit/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.GETFILEPERIZINAN](data) {
    ApiService.setHeader();
    return ApiService.get(`ProsesPermohonan/getFileIzin/${data.id}`);
  }
}
