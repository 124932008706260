import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class RestServiceModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETRESTSERVICES](data) {
    ApiService.setHeader();
    return ApiService.get(
      `rest_services?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }
  @Action({ rawError: true })
  [Actions.SAVERESTSERVICE](data) {
    ApiService.setHeader();
    return ApiService.post(`rest_services`, data);
  }
  @Action({ rawError: true })
  [Actions.UPDATERESTSERVICE](data) {
    ApiService.setHeader();
    return ApiService.put(`rest_services/${data.id.id}`, data.data);
  }
  @Action({ rawError: true })
  [Actions.GETDATATABELLIST](params) {
    ApiService.setHeader();
    return ApiService.get(`datatabel/getlist?q=${params.q}&limit=100`);
  }
  @Action({ rawError: true })
  [Actions.GETINSTANSILIST](params) {
    ApiService.setHeader();
    return ApiService.get(`unit/instansilist?q=${params.q}&limit=100`);
  }
  @Action({ rawError: true })
  [Actions.DELETERESTSERVICE](id) {
    ApiService.setHeader();
    return ApiService.delete(`rest_services/${id}`);
  }
}
