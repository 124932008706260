import ApiService from "@/core/services/ApiService";
import store from "@/store/index";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class TemplateDataModule extends VuexModule {
  @Action
  [Actions.TEMPLATEDATA](data) {
    ApiService.setHeader();

    if (data.method === "GETLIST") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch("templateDataStore/setList", data.data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "GETLISTS") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch("templateDataStore/setLists", data.data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "GETTIPEKELUARAN") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch(
              "templateDataStore/listsTipeKeluaran",
              data.data.items
            );
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "GETJENISSUMBER") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch(
              "templateDataStore/listsJenisSumber",
              data.data.items
            );
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "GETTIPEKELOMPOK") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch(
              "templateDataStore/listsTipeKelompok",
              data.data.items
            );
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "GETPROFILE") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch("templateDataStore/setProfile", data.data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "GETTIPEJOIN") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch("templateDataStore/setTipeJoin", data.data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "GETTIPEKONDISI") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch("templateDataStore/setTipeKondisi", data.data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "GETTIPERELASI") {
      return ApiService.get(data.filter)
        .then(({ data }) => {
          if (data.success === true) {
            store.dispatch("templateDataStore/setTipeRelasi", data.data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          this.context.commit(Mutations.SET_ERROR, response.data.status);
        });
    }

    if (data.method === "POSTQUERY") {
      return ApiService.post(data.filter, data.data);
    }

    if (data.method === "POST") {
      return ApiService.post(data.filter, data.data);
    }

    if (data.method === "PUT") {
      return ApiService.put(data.filter, data.data);
    }

    if (data.method === "DELETE") {
      return ApiService.delete(data.filter);
    }

    if (data.method === "PUTCOPY") {
      return ApiService.put(data.filter, data.data);
    }
  }
}
