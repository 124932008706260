import ApiService from "@/core/services/ApiService";
import { Apis } from "@/store/enums/StoreApp";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class VideoModule extends VuexModule {
  @Action({ rawError: true })
  [Apis.VIDEOMODULE](data) {
    ApiService.setHeader();
    if (data.method == "GET") {
      return ApiService.get(`video${data.filter}`);
    }

    if (data.method == "POST") {
      return ApiService.post(`video${data.filter}`, data.data);
    }

    if (data.method == "PUT") {
      return ApiService.put(`video${data.filter}`, data.data);
    }

    if (data.method == "DEL") {
      return ApiService.delete(`video${data.filter}`);
    }
  }
}
