export default {
  namespaced: true,
  state: {
    alurProses: {},
    tipeProses: [],
    jenisAlurProses: [],
    tautanProses: [],
    formProses: [],
    reportProses: [],
  },
  mutations: {
    SET_ALUR_PROSES_DETAIL(state, valueArr) {
      state.alurProses = valueArr;
    },
    SET_JENIS_ALUR_PROSES(state, valueArr) {
      state.jenisAlurProses = valueArr;
    },
    SET_TIPE_PROSES(state, valueArr) {
      state.tipeProses = valueArr;
    },
    SET_TAUTAN_PROSES(state, valueArr) {
      state.tautanProses = valueArr;
    },
    SET_FORM_PROSES(state, valueArr) {
      state.formProses = valueArr;
    },
    SET_REPORT_PROSES(state, valueArr) {
      state.reportProses = valueArr;
    },
  },
  actions: {
    setAlurDetail({ commit }, payload) {
      commit("SET_ALUR_PROSES_DETAIL", payload);
    },
    setJenisAlurProses({ commit }, payload) {
      commit("SET_JENIS_ALUR_PROSES", payload);
    },
    setTipeProses({ commit }, payload) {
      commit("SET_TIPE_PROSES", payload);
    },
    setTautanProses({ commit }, payload) {
      commit("SET_TAUTAN_PROSES", payload);
    },
    setFormProses({ commit }, payload) {
      commit("SET_FORM_PROSES", payload);
    },
    setReportProses({ commit }, payload) {
      commit("SET_REPORT_PROSES", payload);
    },
  },
};
