import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import createPersistedState from "vuex-persistedstate";

import AuthModule from "@/store/modules/AuthModule";
import AduanModule from "@/store/modules/AduanModule";
import AduanPemohonModules from "@/store/modules/AduanPemohonModules";
import ServiceEksternalModule from "@/store/modules/ServiceEksternalModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

// Apps Module
import AlurProsesModule from "@/store/modules/AlurProsesModule";
import MenuModule from "@/store/modules/MenuModule";
import InstansiModule from "@/store/modules/InstansiModule";
import PegawaiModule from "@/store/modules/PegawaiModule";
import SimulasiTarifModule from "@/store/modules/SimulasiTarifModule";
import KamusDataModule from "@/store/modules/KamusDataModule";
import TemplateDataModule from "@/store/modules/TemplateDataModule";
import ProvinsiModule from "@/store/modules/ProvinsiModule";
import KabupatenModule from "@/store/modules/KabupatenModule";
import KecamatanModule from "@/store/modules/KecamatanModule";
import DesaModule from "@/store/modules/DesaModule";
import PeranModule from "@/store/modules/PeranModule";
import PenggunaModule from "@/store/modules/PenggunaModule";
import DaftarAduanModule from "@/store/modules/DaftarAduanModule";
import KalenderModule from "@/store/modules/KalenderModule";
import SettingGrafikModule from "@/store/modules/SettingGrafikModule";
import PenggunaRestModule from "@/store/modules/PenggunaRestModule";
import PenggunaSmsModule from "@/store/modules/PenggunaSmsModule";
import BidangUsahaModule from "@/store/modules/BidangUsahaModule";
import JenisUsahaModule from "@/store/modules/JenisUsahaModule";
import AduanKategoriModule from "@/store/modules/AduanKategoriModule";
import JenisProsesModule from "@/store/modules/JenisProsesModule";
import FormModule from "@/store/modules/FormModule";
import ReportComponent from "@/store/modules/ReportComponent";
import ProsesNib from "@/store/modules/ProsesNib";
import VideoModule from "@/store/modules/VideoModule";
import TarifModule from "@/store/modules/TarifModule";
import RegisterPemohonModule from "@/store/modules/RegisterPemohonModule";
import ComboGrid from "@/store/modules/ComboGrid";
import TTEModule from "@/store/modules/TTEModule";
import ProsesPermohonanModule from "@/store/modules/ProsesPermohonanModule";
import DetailPemohonModule from "@/store/modules/DetailPemohonModule";
import PermohonanIzinAdminModule from "@/store/modules/PermohonanIzinAdmin";
import JenisIzinModule from "@/store/modules/JenisIzinModule";
import ArtikelKategoriModule from "@/store/modules/ArtikelKategoriModule";
import ArtikelModule from "@/store/modules/ArtikelModule";
import SurveyModule from "@/store/modules/SurveyModule";
import PenomoranModule from "@/store/modules/PenomoranModules";
import InformasiModule from "@/store/modules/InformasiModules";
import GaleriModule from "@/store/modules/GaleriModules";
import ProsedurOperasionalModule from "@/store/modules/ProsedurOperasionalModules";
import SlaModule from "@/store/modules/SlaModule";
import NotifikasiModule from "@/store/modules/NotifikasiModules";
import DashboardStatisticModule from "@/store/modules/DashboardStatisticModule";
import PermohonanIDitolakModule from "@/store/modules/PermohonanIDitolakModule";
import DaftarizinModule from "@/store/modules/DaftarIzinModule";
import DaftarPemohonModule from "@/store/modules/DaftarPemohonModule";
import JenisDokumenModule from "@/store/modules/JenisDokumenmModules";
import FaqKategoriModule from "@/store/modules/FaqKategoriModule";
import FaqModule from "@/store/modules/FaqModule";
import VerifyTokenModule from "@/store/modules/VerifyTokenModule";
import AssessmentModule from "@/store/modules/AssessmentModule";
import JenisIzinKategoriModule from "@/store/modules/JenisIzinKategoriModule";
import IntegrasiKKIModule from "@/store/modules/IntegrasiKKIModule";
import SliderModule from "@/store/modules/SliderModules";
import NotifikasiUserModule from "@/store/modules/NotifikasiUserModule";
import RestServiceModule from "@/store/modules/RestServiceModules";
import RatingPerizinan from "@/store/modules/RatingPerizinan";
import KkiModule from "@/store/modules/KkiModule";
import IntegrasiSISDMKModule from "@/store/modules/IntegrasiSISDMKModule";
import ValidasiAkesModule from "@/store/modules/ValidasiAkesModule";
import LogSenderMailModule from "./modules/LogSenderMailModule";
import ConcurentModule from "./modules/ConcurentModule";
import TableModule from "@/store/modules/TableModule";

// application
import appsStore from "./dataAppStore/Apps";
import alurProses from "./dataAppStore/AlurProses";
import serviceEksternalStore from "./dataAppStore/ServiceEksternal";
import menuStore from "./dataAppStore/Menu";
import instansiStore from "./dataAppStore/Instansi";
import pegawaiStore from "./dataAppStore/Pegawai";
import simulasiTarifStore from "./dataAppStore/SimulasiTarif";
import kamusDataStore from "./dataAppStore/KamusData";
import templateDataStore from "./dataAppStore/TemplateData";
import provinsiStore from "./dataAppStore/Provinsi";
import kabupatenStore from "./dataAppStore/Kabupaten";
import kecamatanStore from "./dataAppStore/Kecamatan";
import desaStore from "./dataAppStore/Desa";
import peranStore from "./dataAppStore/Peran";
import penggunaStore from "./dataAppStore/Pengguna";
import daftarAduanStore from "./dataAppStore/DaftarAduan";
import kalenderStore from "./dataAppStore/Kalender";
import settingGrafikStore from "./dataAppStore/SettingGrafik";
import aduanKategoriStore from "./dataAppStore/AduanKategori";
import penggunaRestStore from "./dataAppStore/PenggunaRest";
import penggunaSmsStore from "./dataAppStore/PenggunaSms";
import bidangUsahaStore from "./dataAppStore/BidangUsaha";
import jenisUsahaStore from "./dataAppStore/JenisUsaha";
import detailPemohonStore from "./dataAppStore/DetailPemohon";
import handleErrorStore from "./dataAppStore/HandleError";
import notifUserStore from "./dataAppStore/NotifikasiUser";

config.rawError = true;
const store = createStore({
  plugins: [createPersistedState()],
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,

    // Data Mutation Apps
    AduanModule,
    AduanPemohonModules,
    ServiceEksternalModule,
    AlurProsesModule,
    MenuModule,
    InstansiModule,
    PegawaiModule,
    SimulasiTarifModule,
    KamusDataModule,
    TemplateDataModule,
    ProvinsiModule,
    KabupatenModule,
    KecamatanModule,
    DesaModule,
    PeranModule,
    PenggunaModule,
    DaftarAduanModule,
    KalenderModule,
    SettingGrafikModule,
    PenggunaRestModule,
    PenggunaSmsModule,
    BidangUsahaModule,
    JenisUsahaModule,
    AduanKategoriModule,
    JenisProsesModule,
    DetailPemohonModule,
    FormModule,
    ReportComponent,
    ProsesNib,
    VideoModule,
    TarifModule,
    RegisterPemohonModule,
    ComboGrid,
    TTEModule,
    PermohonanIzinAdminModule,
    ProsesPermohonanModule,
    JenisIzinModule,
    ArtikelKategoriModule,
    ArtikelModule,
    SurveyModule,
    PenomoranModule,
    InformasiModule,
    GaleriModule,
    ProsedurOperasionalModule,
    SlaModule,
    NotifikasiModule,
    DashboardStatisticModule,
    PermohonanIDitolakModule,
    DaftarizinModule,
    DaftarPemohonModule,
    JenisDokumenModule,
    FaqKategoriModule,
    FaqModule,
    VerifyTokenModule,
    AssessmentModule,
    JenisIzinKategoriModule,
    IntegrasiKKIModule,
    SliderModule,
    NotifikasiUserModule,
    RestServiceModule,
    RatingPerizinan,
    KkiModule,
    ValidasiAkesModule,
    LogSenderMailModule,
    ConcurentModule,
    TableModule,
    IntegrasiSISDMKModule,

    // storeData
    appsStore,
    alurProses,
    serviceEksternalStore,
    menuStore,
    instansiStore,
    pegawaiStore,
    simulasiTarifStore,
    kamusDataStore,
    detailPemohonStore,
    templateDataStore,
    provinsiStore,
    kabupatenStore,
    kecamatanStore,
    desaStore,
    peranStore,
    penggunaStore,
    daftarAduanStore,
    kalenderStore,
    settingGrafikStore,
    aduanKategoriStore,
    penggunaRestStore,
    penggunaSmsStore,
    bidangUsahaStore,
    jenisUsahaStore,
    handleErrorStore,
    notifUserStore,
  },
});

// export store
export default store;
