import store from "@/store/index";

const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem("profile");
  window.localStorage.removeItem("ability");
  window.localStorage.removeItem("role");

  store.dispatch("appsStore/setToken", "");
  store.dispatch("appsStore/setProfile", "");
  store.dispatch("appsStore/setRole", "");
};

// Role
export const saveRole = (role: string): void => {
  window.localStorage.setItem("role", role);
};

// Profile
export const saveProfile = (profile: string[]): void => {
  window.localStorage.setItem("profile", JSON.stringify(profile));
};

// Ability/menu
export const saveAbility = (ability: string[]): void => {
  window.localStorage.setItem("ability", JSON.stringify(ability));
};

export default {
  getToken,
  saveToken,
  destroyToken,
  saveRole,
  saveProfile,
  saveAbility,
};
