import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { lowerCase } from "lodash";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class TTEModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.GETPERMOHONANIZIN](data) {
    ApiService.setHeader();
    return ApiService.get(
      `permohonan_izin?q=${data.search}&page=${data.page}&limit=${data.limit}&begin_date=${data.begin_date}&end_date=${data.end_date}&order=-permohonanizin.id&useSlaList=1&useSlaListIzin=1&pemohon_id=`
    );
  }
  @Action({ rawError: true })
  [Actions.GETPEMOHONLIST](data) {
    ApiService.setHeader();
    return ApiService.get(
      `pemohon?q=${data.search}&page=${data.page}&limit=${data.limit}&order=asc`
    );
  }
  @Action({ rawError: true })
  [Actions.GETPERUSAHAANLIST](data) {
    ApiService.setHeader();
    return ApiService.get(
      `perusahaan?q=${data.search}&page=${data.page}&limit=${data.limit}&order=asc&pemohon_id=${data.pemohon_id}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETINTEGRASIKSWPD](data) {
    ApiService.setHeader();
    return ApiService.get(
      `integrasi/kswp?tipe_validasi=${data.jenis_validasi}&no_validasi=${data.nopol}&izin_id=${data.id}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETINTEGRASIKKI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `integrasi/kki/${data.no}?status=${lowerCase(data.status)}`
    );
  }
  @Action({ rawError: true })
  [Actions.GETINTEGRASIKPUBLIC](uri) {
    ApiService.setHeader();
    return ApiService.get(`${uri}`);
  }
  @Action({ rawError: true })
  [Actions.GETPERUSAHAANPEMOHON](data) {
    ApiService.setHeader();
    return ApiService.get(
      `perusahaan?q=${data.search}&page=${data.page}&limit=${data.limit}&order=asc&pemohon_id=${data.pemohon_id}`
    );
  }
  @Action({ rawError: true })
  [Actions.UPDATEPERMOHONAN](data) {
    ApiService.setHeader();
    return ApiService.put(`permohonan_izin/${data.id}`, data);
  }
  @Action({ rawError: true })
  [Actions.DELETEPERMOHONAN](data) {
    ApiService.setHeader();
    return ApiService.delete(
      `PermohonanIzin/delete/${data.id}?q=${
        data.del == 0 ? "Nonaktifkan" : "Aktifkan"
      }`
    );
  }

  @Action({ rawError: true })
  [Actions.UPLOADDOKUMENPENOLAKAN](data) {
    ApiService.setHeader();
    return ApiService.post(`PermohonanIzin/uploaddokumenpenolakan`, data);
  }

  @Action({ rawError: true })
  [Actions.PERMOHONANIZINDITOLAK](data) {
    ApiService.setHeader();
    return ApiService.put(`permohonan_izin/tolakpermohonan/${data.id}`, data);
  }
}
