import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class JenisProsesModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.JENISPROSES](data) {
    ApiService.setHeader();
    return ApiService.get(
      `jenis_proses?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.DETAILJENISPROSES](id) {
    ApiService.setHeader();
    return ApiService.get(`jenis_proses/${id}`);
  }

  @Action({ rawError: true })
  [Actions.SAVEJENISPROSES](data) {
    ApiService.setHeader();
    return ApiService.post(`jenis_proses`, data);
  }

  @Action({ rawError: true })
  [Actions.UBAHJENISPROSES](data) {
    ApiService.setHeader();
    return ApiService.put(`jenis_proses/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.DELETEJENISPROSES](id) {
    ApiService.setHeader();
    return ApiService.delete(`jenis_proses/${id}`);
  }
}
