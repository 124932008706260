import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class FaqKategoriModule extends VuexModule {
  @Action({ rawError: true })
  [Actions.FAQKATEGORI](data) {
    ApiService.setHeader();
    return ApiService.get(
      `faq_category?q=${data.search}&page=${data.page}&limit=${data.limit}`
    );
  }

  @Action({ rawError: true })
  [Actions.DETAILFAQKATEGORI](id) {
    ApiService.setHeader();
    return ApiService.get(`faq_category/${id}`);
  }

  @Action({ rawError: true })
  [Actions.SAVEFAQKATEGORI](data) {
    ApiService.setHeader();
    return ApiService.post(`faq_category`, data);
  }

  @Action({ rawError: true })
  [Actions.UBAHFAQKATEGORI](data) {
    ApiService.setHeader();
    return ApiService.put(`faq_category/${data.id}`, data);
  }

  @Action({ rawError: true })
  [Actions.DELETEFAQKATEGORI](id) {
    ApiService.setHeader();
    return ApiService.delete(`faq_category/${id}`);
  }
}
