import ApiService from "@/core/services/ApiService";
import { Apis } from "@/store/enums/StoreApp";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class RegisterPemohonModule extends VuexModule {
  @Action({ rawError: true })
  [Apis.REGPEMOHON](data) {
    ApiService.setHeader();
    if (data.method == "resendCredential") {
      return ApiService.get(`pemohon${data.filter}`);
    }

    if (data.method == "GET") {
      return ApiService.get(`pemohon${data.filter}`);
    }

    if (data.method == "POST") {
      return ApiService.post(`pemohon${data.filter}`, data.data);
    }

    if (data.method == "PUT") {
      return ApiService.put(`pemohon${data.filter}`, data.data);
    }

    if (data.method == "DEL") {
      return ApiService.delete(`pemohon${data.filter}`);
    }

    if (data.method == "GETUNIT") {
      return ApiService.get(`unit/instansilist${data.filter}`);
    }

    if (data.method == "GETPROV") {
      return ApiService.get(`provinsi/list${data.filter}`);
    }

    if (data.method == "GETKOTA") {
      return ApiService.get(`kabupaten/list${data.filter}`);
    }

    if (data.method == "GETKEC") {
      return ApiService.get(`kecamatan/list${data.filter}`);
    }

    if (data.method == "GETDESA") {
      return ApiService.get(`desa/list${data.filter}`);
    }
  }
}
